@media (max-width: 992px) {
  .show-lg {
    display: none;
  }
}
.section-head-s2 {
  padding-bottom: 20px !important;
}
.section-l {
  padding-top: 50px !important;
}
@media (max-width: 480px) {
  .right-bars > img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 300px;
    width: 300px;
    -o-object-position: 2em 0 !important;
    object-position: 2em 0 !important;
  }

  .right-bars {
    margin-right: 0;
    top: -2em !important;
    right: 0;
  }
  .left-bars {
    margin-left: 0;
    top: 17em !important;
    left: -1em !important;
    opacity: 0.5;
  }
  .mt-small-30 {
    margin-top: 30px;
  }

  .left-bars > img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 300px;
    width: 300px;
    -o-object-position: -15em 0 !important;
    object-position: -15em 0 !important;
  }
}
@media (min-width: 992px) {
  .show-xs {
    display: none;
  }
}

.left-bars {
  margin-left: 0;
  top: 13em;
  left: -1em;
}
.blockHead {
  font-weight: bold;
  background-color: rgb(240, 185, 11);
  text-transform: uppercase;
  /*margin-bottom: 10px;*/
  margin-bottom: 16px !important;
  display: inline-block;
  padding: 3px 16px;
  color: rgb(23, 23, 23) !important;
}

.left-bars,
.right-bars {
  position: absolute;
  height: 400px;
  width: 200px;
  overflow: hidden;
}
.right-bars {
  margin-right: 0;
  top: 2em;
  right: 0;
}
.left-bars > img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 300px;
  width: 300px;
  -o-object-position: -15em 0;
  object-position: -15em 0;
}
.right-bars > img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 300px;
  width: 300px;
  -o-object-position: 1em 0;
  object-position: 1em 0;
}
ß
img {
  border-style: none;
}
#ecosystems {
  background: no-repeat;
}

.yala {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  opacity: 1;
  cursor: pointer;
  display: flex;
  margin-bottom: 24px;
  /*background: #131418 !important;*/
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #242b30;
  border-image: initial;
  transition: box-shadow 500ms ease 0s, opacity 500ms ease 0s;
  overflow: hidden;
  max-width: 900px;
  margin: 0 auto;
  -webkit-transform: scale(0.97);
  transform: scale(0.97);
  transition: background 0.3s ease, -webkit-transform 0.3s ease;
  transition: transform 0.3s ease, background 0.3s ease;
  transition: transform 0.3s ease, background 0.3s ease,
    -webkit-transform 0.3s ease;
}

.yala:hover,
.yala:active,
.yala:focus {
  transform: scale(0.97);
  transition: background 0.3s ease, -webkit-transform 0.3s ease;
  transition: transform 0.3s ease, background 0.3s ease;
  transition: transform 0.3s ease, background 0.3s ease,
    -webkit-transform 0.3s ease;
  background: #1e1f26 !important;
  border-bottom-color: transparent;
  transform: scale(1);
  border-radius: 4px;
}
.coverImage {
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  background-size: cover;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  /*border-left: px solid rgb(231, 235, 238);*/
}
.sturp {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 24px;
}
.card-de {
  display: flex;
  margin-top: 24px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  /* -webkit-box-flex: 0;
    flex-grow: 0;*/
}
.feature-text p {
  font-size: 13px;
}
.yala .col-xxl-6 img {
  width: 300px;
  /* margin: auto;
    display: block; */
}
.reno {
  margin: auto;
  text-align: center;
}

.card2 {
  background: #343d45;
  width: 200px;
  margin: 0 auto;
  box-shadow: 0 8px 24px rgba(44, 49, 52, 0.1),
    0 16px 32px rgba(44, 49, 52, 0.08) !important;
  border-radius: 10px !important;
}
.pa-4 {
  padding: 24px !important;
}
.card2 img {
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  padding: 10px;
  display: block;
  width: 100px;
}
.ard-4 {
  margin-right: 0;
  top: -7em;
  right: 0;
  position: absolute;
  width: 500px;
}
.pricing {
  color: #04b168;
  font-weight: bold;
}
.doc-text {
  text-align: center;
  font-size: 13px;
  padding-bottom: 20px;
}
.doc-text h5 {
  padding: 8px;
  text-align: center;
}
