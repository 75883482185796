
.txt-center{
  text-align: center
}

.fix-pad{
  padding: 0px 20px 0px 20px;
}

.feature-block{
  margin-bottom: 30px;
  border-radius: 10px;
  display: block;
  background:#12161c;
  padding: 30px 30px 60px 30px;
  box-shadow: 5px 0 15px rgba(0,0,0,.25);
  transform: scale(.95);
  transition: all .3s ease;

}

/* .menu-btn{
  margin: -10px 10px 26px -10px;
} */
ol, ul, dl{
  margin-bottom: 0em;
}
.mb-auto,
.my-auto {
  margin-bottom: 40px !important
}
.bg-blu{
  background: #1178c1;
}
.bg-pink{
  background:#b276d1
   /* #ce96a0; */
}
.bg-greenie{ background: #04b169 !important}
.bg-gold{
  background: #bf9612;
}
.menu-item a{
  font-weight: bolder !important;
  font-size: 13px !important;
}
.font-w{
  font-weight: bolder !important;
}
.has-fixed.is-dark .header-main{
  background-color: #0b0e11;
}
.logo-link h4{
  color: #fff;
  font-weight: bolder;
}
.light-blue{
  box-shadow: rgba(97, 218, 251, 0.7) 0px 0px 100px;
}
.feature-block:hover, .feature-block:focus, .feature-block:active{
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all .3s ease
}

.cmc-price{
  text-align: center;
}
.cmc-price h3{
  font-size: 20px;
  font-weight: 200;
}
.cmc-price small{
  font-size: 1em;
}
.badge2{
  margin-right: 4px;
  padding: 3px 4px;
  color: #fff;
  border-radius: 4px;
  background-color: #b276d1;
}
.hero-announcement__content{
display: block ;
padding: 8px;
text-decoration: none;
color: #fff;
border-radius: 8px;
background: #092333;
font-weight: bolder;
}

.btn{
  font-size: 12px;
  color: #fff;
  border: 2px solid #04b169 ;
  background-color: transparent;
  font-weight: bolder;
  padding-left: 18px;
  padding-right: 18px;
  letter-spacing: 0.05em;
  
}
.btn:not(.btn-outline):hover {
  border-color: #fff;
  background-color: #16a3fe;
  color: #fff;
}
.hero-announcement__content{
  text-align: center;
}
.fix-pad{
color: #fff !important;
}
#ecosystems{
  background: transparent;
}

.avatar-d{
  display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 19px;
    /* font-size: 1.9rem; */
    font-weight: 300;
    line-height: 32px;
    letter-spacing: -1px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    color: #fff;
    background-color: #156299;
}

.menu-d{
    top: 12px;
    margin-left: 15px;
    font-size: 20px;
    position: relative;
}
.menu-lo{
  margin-bottom: -25px;
}
.lambda em{
  color: #0f8959;
  font-size: 16px;
  margin-right: 6px;
}
.dashboard .menu-btns{
  margin-bottom: -30px !important;
}
@media (min-width: 992px){
.dashboard .menu-btns {
  /* display: f; */
  margin-bottom: -30px !important;
  margin-left: 10px;
}
}
.settings .has-fixed.is-dark .header-main{
  background: #1f374a !important;
}
.has-fixed.is-dark .header-main

.settings .header-main{
  background-color: transparent !important;
}

@media (min-width: 768px){
.hero-announcement__content {
  display: inline ;
}
}
.hero-announcement {
  position: relative;
  margin: -1rem 0 3rem;
  text-align: center;
}
.menu-link {
  font-family: "Roboto", sans-serif !important;
  font-weight: bolder !important;
  font-size: 13px !important;
}
.money-bg{
  display: flex;
flex-direction: column;
-moz-box-pack: center;
justify-content: center;
/* background-color: #434f5a; */
/* border: 1px solid rgb(203, 210, 214); */
border-radius: 6px;
height: 100%;
width: 100%;
min-height: 1.5rem;
padding: 0.75rem 1.5rem;
margin-bottom: 1.5rem;
box-sizing: inherit;
font-size: 15px;
text-align: left;
color: rgb(44, 46, 47);
/* -moz-box-align: center; */
/* align-items: center; */
}
.head-money{
display: flex;
-moz-box-pack: justify;
justify-content: space-between;
-moz-box-align: center;
align-items: center;
width: 100%;
color: #c082e0;
font-size: 15px;
text-align: left;
font-weight: 500;
margin-bottom: 10px;
}
.money-d{
  text-align: left;
  padding: 18px;
  background: #242b30;
}
.money-avail{
  font-size: 14px;
font-weight: 300;
margin-bottom: 0px;
margin-top: 10px;
color: rgb(139, 141, 144);
}
.balance{
  font-weight: 300 !important;
font-size: 38px !important;
overflow-wrap: break-word;
line-height: 1em;
}
.vid-head{
  display: flex;
}
.vid-head p img{
  padding-right: 8px;
  width: 25px
}
.vid-head span{
  color:#fff;
  text-transform: capitalize !important;
  font-weight: bold;
}
.table-des{
  color: #c7c9d3;
}
.explore-vid{
  color:#0ebeff;
}
.earned{
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1.5px solid #076daf;
}
.earned p{
  color: #04c172;
  font-weight: bold;
  font-size: 14px;
}
@media (max-width: 480px){
.left-bars {
  margin-left: 0;
  top: 47em!important;
  left: -1em!important;
  opacity: .5;
}
}
.margin-auto{
  margin: auto !important;
}


/* login styles */

.ath-body, .quiz-body{
  box-shadow: 0 1px 46px -4px rgba(0,0,0,.28);
  border-top: 3px solid #05b169;
  background: #12161c !important;
  color: #fff;

}
.signlog{
  height: 58px;
}
.ath-heading{
  color: #fff;
  font-weight: 350;
}
.tc-default, .tc-default a{
  color: #afaeae !important;
}
.adj-size{
  margin: auto;
  display: block;
  height: 100px;
}
.ath-tite{
  font-size: 20px;
  margin-top: 20px;
}
.txtc{
  text-align: center;
  color: #ccc !important;
  font-size: 13px;
}
.mTop-40{
  margin-top: 40px;
}
.bg-pic{
background: url('./assets/images/bg.svg');
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
}
.ath-body{
  box-shadow: 0 1px 46px -4px rgba(0,0,0,.28);
  border-top: 3px solid #05b169;
  background: #12161c;
  color: #fff;

}
.signlog{
  height: 60px;
}
.ath-heading{
  color: #fff;
  font-weight: 350;
}
.tc-default, .tc-default a{
  color: #afaeae !important;
}
.adj-size{
  margin: auto;
  display: block;
  height: 100px;
}
.ath-tite{
  font-size: 20px;
  margin-top: 20px;
}
/* .txtc{
  text-align: center
} */
.mTop-40{
  margin-top: 40px;
}
.mBottom-40{
  margin-bottom: 40px;
}
.field-item label{
color:#afaeae;
font-size: 13px;
}
.mTop-20{
  margin-top: 20px;

}
.dash_header{
  color: #16a3fe;
  font-weight: bold;
}

.txt-center{
  text-align: center
}

.fix-pad{
  padding: 0px 20px 0px 20px;
}
.feature-block{
  margin-bottom: 30px;
  border-radius: 10px;
  display: block;
  background:#12161c;
  padding: 30px 30px 60px 30px;
  box-shadow: 5px 0 15px rgba(0,0,0,.25);
  transform: scale(.95);
  transition: all .3s ease;

}
/* .menu-btn{
  margin: -10px 10px 26px -10px;
} */
ol, ul, dl{
  margin-bottom: 0em;
}
.mb-auto,
.my-auto {
  margin-bottom: 40px !important
}
.bg-blu{
  background: #1178c1;
}
.bg-pink{
  background:#b276d1
   /* #ce96a0; */
}
.bg-gold{
  background: #bf9612;
}
.menu-item a{
  font-weight: bolder;
  font-size: 13px;
}
.font-w{
  font-weight: bolder !important;
}
.has-fixed.is-dark .header-main{
  background-color: #0b0e11;
}
.logo-link h4{
  color: #fff;
  font-weight: bolder;
}
.light-blue{
  box-shadow: rgba(97, 218, 251, 0.7) 0px 0px 100px;
}
.feature-block:hover, .feature-block:focus, .feature-block:active{
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all .3s ease
}

.cmc-price{
  text-align: center;
}
.cmc-price h3{
  font-size: 20px;
  font-weight: 200;
}
.cmc-price small{
  font-size: 1em;
}
.badge2{
  margin-right: 4px;
  padding: 3px 4px;
  color: #fff;
  border-radius: 4px;
  background-color: #b276d1;
}
.hero-announcement__content{
display: block ;
padding: 8px;
text-decoration: none;
color: #fff;
border-radius: 8px;
background: #092333;
font-weight: bolder;
}

.btn{
  font-size: 12px;
  color: #fff;
  border: 2px solid #04b169;
  background-color: transparent;
  font-weight: bolder;
  padding-left: 18px;
  padding-right: 18px;
  letter-spacing: 0.05em;
}
.btn:not(.btn-outline):hover {
  border-color: #fff;
  background-color: #16a3fe;
  color: #fff;
}
.hero-announcement__content{
  text-align: center;
}
.fix-pad{
color: #fff !important;
}
#ecosystems{
  background: transparent;
}

.avatar-d{
  display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 19px;
    /* font-size: 1.9rem; */
    font-weight: 300;
    line-height: 32px;
    letter-spacing: -1px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    color: #fff;
    background-color: #156299;
}

.menu-d{
    top: 12px;
    margin-left: 15px;
    font-size: 20px;
    position: relative;
}
.menu-lo{
  margin-bottom: -25px;
}
.lambda em{
  color: #0f8959;
  font-size: 16px;
  margin-right: 6px;
}

.settings .has-fixed.is-dark .header-main{
  background: #1f374a !important;
}
.has-fixed.is-dark .header-main

.settings .header-main{
  background-color: transparent !important;
}

@media (min-width: 768px){
.hero-announcement__content {
  display: inline ;
}
}
.hero-announcement {
  position: relative;
  margin: -1rem 0 3rem;
  text-align: center;
}

.money-bg{
  display: flex;
flex-direction: column;
-moz-box-pack: center;
justify-content: center;
/* background-color: #434f5a; */
/* border: 1px solid rgb(203, 210, 214); */
border-radius: 6px;
height: 100%;
width: 100%;
min-height: 1.5rem;
padding: 0.75rem 1.5rem;
margin-bottom: 1.5rem;
box-sizing: inherit;
font-size: 15px;
text-align: left;
color: rgb(44, 46, 47);
/* -moz-box-align: center; */
/* align-items: center; */
}
.head-money{
display: flex;
-moz-box-pack: justify;
justify-content: space-between;
-moz-box-align: center;
align-items: center;
width: 100%;
color: #c082e0;
font-size: 15px;
text-align: left;
font-weight: 500;
margin-bottom: 10px;
}
.money-d{
  text-align: left;
  padding: 18px;
  background: #242b30;
}
.money-avail{
  font-size: 14px;
font-weight: 300;
margin-bottom: 0px;
margin-top: 10px;
color: rgb(139, 141, 144);
}
.balance{
  font-weight: 300 !important;
font-size: 38px !important;
overflow-wrap: break-word;
line-height: 1em;
}
.vid-head{
  display: flex;
}
.vid-head p img{
  padding-right: 8px;
  width: 25px
}
.vid-head span{
  color:#fff;
  text-transform: uppercase;
}
.table-des{
  color: #c7c9d3;
}
.explore-vid{
  color:#0ebeff;
}
.earned{
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1.5px solid #076daf;
}
.earned p{
  color: #04c172;
  font-weight: bold;
  font-size: 14px;
}
@media (max-width: 480px){
.left-bars {
  margin-left: 0;
  top: 47em!important;
  left: -1em!important;
  opacity: .5;
}
}
.coin-hold{
  margin: 0px 20px;
  border-bottom: 1px solid rgb(233, 236, 240);
  animation: 1s ease 0s 1 normal none running eMLfYp;
  -webkit-tap-highlight-color: transparent;
  height: 70px;
  padding: 0px;
  display: flex;
}
.coin-info{
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
}
.coin-cointainer{
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.coin-image{
  width: 30px;
  height: 30px;
  transition: all 0.2s ease-in-out 0s;
  opacity: 1;
}
.coin-name{
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 1;
  letter-spacing: -0.3px;
  /* color: rgb(5, 15, 25); */
}
.currency-amount{
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-direction: column;
}
.explore-vid{
  color: gainsboro;
}
.c-grey{
  color: grey;
}
.yala{
  transition: transform .3s ease,background .3s ease !important;
  -moz-transition: transform .3s ease,background .3s ease;
  --webkit-transition: transform .3s ease,background .3s ease;
  -o-transition: transform .3s ease,background .3s ease;
  transform: scale(0.97);
  transition: background 0.3s ease, -webkit-transform 0.3s ease;
  transition: transform 0.3s ease, background 0.3s ease;
  transition: transform 0.3s ease, background 0.3s ease,
    -webkit-transform 0.3s ease;
}
.yala:hover,
.yala:active,
.yala:focus {
  transform: scale(0.97);
  transition: background 0.3s ease, -webkit-transform 0.3s ease;
  transition: transform 0.3s ease, background 0.3s ease;
  transition: transform 0.3s ease, background 0.3s ease,
    -webkit-transform 0.3s ease;
  background: #1e1f26 !important;
  border-bottom-color: transparent;
  transform: scale(1);
  border-radius: 4px;
}
.ant-tabs-tabpane .ant-tabs-tabpane-active{
background-color: #0b0c10;
height: 100vh;
}
.bg-message{
  background-color: #2a2f33 ;
}
.bg-head-image{
  background:linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ), url("./assets/images/quiz-bg2.jpg") !important;
  background-size: cover
}
.flip-countdown,
.theme-dark {
  background: #13161c !important;
}