/*!
 * Theme Name: ICOCrypto
 * Author: Softnio
 * Author URI: http://themeforest.net/user/softnio
 * Version: 1.6.0
 * Updated: 03.16.2019
**/
/*!
----------------------
## TABLE CONTNETS ##
01. Fonts
02. Utilities
--  02.01 Reset
--  02.02 Spacing
--  02.03 Colors
--  02.04 Misc
03. Elements
--  03.01 Alert
--  03.02 Buttons
--  03.03 Title
--  03.04 List
--  03.05 Form
--  03.06 Table
--  03.07 Progress
--  03.08 CountDown
--  03.09 Toastr
--  03.10 Misc
04. Components
--  04.01 Tab
--  04.02 Accordion
--  04.03 Blocks
--  04.04 Box
--  04.05 Banner
--  04.06 Head
--  04.07 Features
--  04.08 Roadmap
--  04.09 Token
--  04.10 Team
--  04.11 Partners
--  04.12 Contact
--  04.13 WGS
--  04.14 Others
05. Layouts
--  05.01 Header
--  05.02 Menu
--  05.03 Sections
--  05.04 Footer
06. Override
07. Preview Purpose 
---------------------- **/
/** IMPORTANT NOTE: DO NOT Edit this file. Best to write own code in css/theme.css file. It will helps you to easy update of the template. */
/** 01. Fonts */
/* @font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Light.eot"); src: local("Roboto Light"), local("Roboto-Light"), url("../fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Light.woff2") format("woff2"), url("../fonts/Roboto-Light.woff") format("woff"), url("../fonts/Roboto-Light.ttf") format("truetype"); font-weight: 300; font-style: normal; }
@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Regular.eot"); src: local("Roboto"), local("Roboto-Regular"), url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype"); font-weight: normal; font-style: normal; }
@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Medium.eot"); src: local("Roboto Medium"), local("Roboto-Medium"), url("../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Medium.woff2") format("woff2"), url("../fonts/Roboto-Medium.woff") format("woff"), url("../fonts/Roboto-Medium.ttf") format("truetype"); font-weight: 500; font-style: normal; }
@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Bold.eot"); src: local("Roboto Bold"), local("Roboto-Bold"), url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype"); font-weight: bold; font-style: normal; }
@font-face { font-family: 'Quicksand'; src: url("../fonts/Quicksand-Light.eot"); src: local("Quicksand Light"), local("Quicksand-Light"), url("../fonts/Quicksand-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Quicksand-Light.woff2") format("woff2"), url("../fonts/Quicksand-Light.woff") format("woff"), url("../fonts/Quicksand-Light.ttf") format("truetype"); font-weight: 300; font-style: normal; }
@font-face { font-family: 'Quicksand'; src: url("../fonts/Quicksand-Medium.eot"); src: local("Quicksand Medium"), local("Quicksand-Medium"), url("../fonts/Quicksand-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Quicksand-Medium.woff2") format("woff2"), url("../fonts/Quicksand-Medium.woff") format("woff"), url("../fonts/Quicksand-Medium.ttf") format("truetype"); font-weight: 500; font-style: normal; }
@font-face { font-family: 'Quicksand'; src: url("../fonts/Quicksand-Bold.eot"); src: local("Quicksand Bold"), local("Quicksand-Bold"), url("../fonts/Quicksand-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Quicksand-Bold.woff2") format("woff2"), url("../fonts/Quicksand-Bold.woff") format("woff"), url("../fonts/Quicksand-Bold.ttf") format("truetype"); font-weight: bold; font-style: normal; } */
/** 02. Utilities */
/** 02.01 Reset */
html, body { font-family: "Roboto", sans-serif; color: #415076; font-size: 14px; line-height: 1.6; font-weight: 300; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; position: relative; }

@media (min-width: 576px) { html, body { font-size: 16px; line-height: 1.6; font-weight: 300; } }
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section { display: block; }

*[hidden] { display: none; }

ol, ul { list-style: none; }

blockquote, q { quotes: none; }
blockquote::after, blockquote::before, q::after, q::before { content: none; }

table { border-collapse: collapse; border-spacing: 0; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { color: #1940b0; font-weight: 500; font-family: "Roboto", sans-serif; line-height: 1.3; margin-bottom: 0.5rem; }
h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, .h1:last-child, .h2:last-child, .h3:last-child, .h4:last-child, .h5:last-child, .h6:last-child { margin-bottom: 0; }
.tc-light h1, .tc-light a:not(.btn) h1, .tc-light h2, .tc-light a:not(.btn) h2, .tc-light h3, .tc-light a:not(.btn) h3, .tc-light h4, .tc-light a:not(.btn) h4, .tc-light h5, .tc-light a:not(.btn) h5, .tc-light h6, .tc-light a:not(.btn) h6, .tc-light .h1, .tc-light a:not(.btn) .h1, .tc-light .h2, .tc-light a:not(.btn) .h2, .tc-light .h3, .tc-light a:not(.btn) .h3, .tc-light .h4, .tc-light a:not(.btn) .h4, .tc-light .h5, .tc-light a:not(.btn) .h5, .tc-light .h6, .tc-light a:not(.btn) .h6 { color: #fff; }

p { margin-bottom: 1rem; }
p:last-child { margin-bottom: 0; }

ul, ol, table { margin: 0; padding: 0; }

strong, b { font-weight: 500; }

a { outline: 0 none; transition: all 0.5s; color: #16a3fe; }
a:link, a:visited { text-decoration: none; }
a:hover, a:focus, a:active { outline: 0; color: #23c99d; }

img { max-width: 100%; height: auto; }

h1, .h1, .fz-1 { font-size: 2.75rem; }

h2, .h2, .fz-2 { font-size: 2.25rem; }

h3, .h3, .fz-3 { font-size: 1.87rem; }

h4, .h4, .fz-4 { font-size: 1.6rem; }

h5, .h5, .fz-5 { font-size: 1.2rem; }

h6, .h6, .fz-6 { font-size: 0.94rem; }

.fz-7 { font-size: 0.88rem; }

.fz-8 { font-size: 0.75rem; }

.lead { font-weight: 300; }

.ard-3 {
margin-right: 0;
top: 10em;
right: 0;
position: absolute;
width: 400px;
overflow: hidden;
}
.nk-df-content, .nk-ovm:before, .nk-ovm:after { content: ""; }
.nk-df-rel, .has-ovm, .has-bg, [class*='bg-'], .has-bg-image { position: relative; }
.nk-df-over-up, .has-ovm > [class*=container], .has-ovm > :not(.nk-ovm):not(.header-main):not(.nk-header), .has-bg-image > [class*=container] { position: relative; z-index: 5; }
.nk-df-t, .nk-df-y { top: 0; }
.nk-df-b, .nk-df-y { bottom: 0; }
.nk-df-l, .nk-df-x { left: 0; }
.nk-df-r, .nk-df-x { right: 0; }
.nk-df-around, .nk-ovm, .nk-ovm:before, .nk-ovm:after, .bg-image, .overlay:after, .nk-ovm .nk-svgs, .progress-percent, .accordion-icon:before, .accordion-icon:after { position: absolute; bottom: 0; top: 0; left: 0; right: 0; }
.nk-df-center, .nk-block-video-play { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
.nk-df-bg, .nk-ovm, .nk-ovm:before, .nk-ovm:after, .has-bg, [class*='bg-'], .bg-image { background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; }
.nk-df-bg-100, .img-block-dot, .app-slide-wrap:after, .rank-item { background-repeat: no-repeat; background-size: 100%; background-position: 50% 50%; }

/** 02.02 Spacing */
.gap { height: 30px; }

.gap-s { height: 10px; }

.gap-m { height: 20px; }

.gap-l { height: 60px; }

.gap-x { height: 90px; }

.gap-1x { height: 10px; }

.gap-2x { height: 20px; }

.gap-3x { height: 30px; }

.gap-4x { height: 40px; }

.gap-5x { height: 50px; }

.gap-6x { height: 60px; }

.gap-7x { height: 70px; }

.gap-8x { height: 80px; }

.gap-9x { height: 90px; }

.gap-10x { height: 100px; }

.pdt, .pd, .pdy { padding-top: 30px; }

.pdb, .pd, .pdy { padding-bottom: 30px; }

.pdl, .pd, .pdx { padding-left: 30px; }

.pdr, .pd, .pdx { padding-right: 30px; }

.pdt-s, .pd-s, .pdy-s { padding-top: 10px !important; }

.pdt-m, .pd-m, .pdy-m { padding-top: 20px !important; }

.pdt-r, .pd-r, .pdy-r { padding-top: 30px !important; }

.pdt-l, .pd-l, .pdy-l { padding-top: 60px !important; }

.pdt-x, .pd-x, .pdy-x { padding-top: 90px !important; }

.pdb-s, .pd-s, .pdy-s { padding-bottom: 10px !important; }

.pdb-m, .pd-m, .pdy-m { padding-bottom: 20px !important; }

.pdb-r, .pd-r, .pdy-r { padding-bottom: 30px !important; }

.pdb-l, .pd-l, .pdy-l { padding-bottom: 60px !important; }

.pdb-x, .pd-x, .pdy-x { padding-bottom: 90px !important; }

.pdl-s, .pd-s, .pdx-s { padding-left: 10px !important; }

.pdl-m, .pd-m, .pdx-m { padding-left: 20px !important; }

.pdl-r, .pd-r, .pdx-r { padding-left: 30px !important; }

.pdl-l, .pd-l, .pdx-l { padding-left: 60px !important; }

.pdl-x, .pd-x, .pdx-x { padding-left: 90px !important; }

.pdr-s, .pd-s, .pdx-s { padding-right: 10px !important; }

.pdr-m, .pd-m, .pdx-m { padding-right: 20px !important; }

.pdr-r, .pd-r, .pdx-r { padding-right: 30px !important; }

.pdr-l, .pd-l, .pdx-l { padding-right: 60px !important; }

.pdr-x, .pd-x, .pdx-x { padding-right: 90px !important; }

.mgt, .mg, .mgy { margin-top: 30px; }

.mgb, .mg, .mgy { margin-bottom: 30px; }

.mgl, .mg, .mgx { margin-left: 30px; }

.mgr, .mg, .mgx { margin-right: 30px; }

.mg-fix { margin-bottom: -20px; }
.mg-fix-lg { margin-bottom: -45px; }
.mg-fix-md { margin-bottom: -30px; }
.mg-fix-sm { margin-bottom: -15px; }
.mg-fix-xs { margin-bottom: -10px; }

.mgt-s, .mg-s, .mgy-s { margin-top: 10px !important; }

.mgt-m, .mg-m, .mgy-m { margin-top: 20px !important; }

.mgt-r, .mg-r, .mgy-r { margin-top: 30px !important; }

.mgt-l, .mg-l, .mgy-l { margin-top: 60px !important; }

.mgt-x, .mg-x, .mgy-x { margin-top: 90px !important; }

.mgb-s, .mg-s, .mgy-s { margin-bottom: 10px !important; }

.mgb-m, .mg-m, .mgy-m { margin-bottom: 20px !important; }

.mgb-r, .mg-r, .mgy-r { margin-bottom: 30px !important; }

.mgb-l, .mg-l, .mgy-l { margin-bottom: 60px !important; }

.mgb-x, .mg-x, .mgy-x { margin-bottom: 90px !important; }

.mgl-s, .mg-s, .mgx-s { margin-left: 10px !important; }

.mgl-m, .mg-m, .mgx-m { margin-left: 20px !important; }

.mgl-r, .mg-r, .mgx-r { margin-left: 30px !important; }

.mgl-l, .mg-l, .mgx-l { margin-left: 60px !important; }

.mgl-x, .mg-x, .mgx-x { margin-left: 90px !important; }

.mgr-s, .mg-s, .mgx-s { margin-right: 10px !important; }

.mgr-m, .mg-m, .mgx-m { margin-right: 20px !important; }

.mgr-r, .mg-r, .mgx-r { margin-right: 30px !important; }

.mgr-l, .mg-l, .mgx-l { margin-right: 60px !important; }

.mgr-x, .mg-x, .mgx-x { margin-right: 90px !important; }

.mgb-m10 { margin-bottom: -10px; }

.mgb-m20 { margin-bottom: -20px; }

.mgb-m30 { margin-bottom: -30px; }

.mgb-m40 { margin-bottom: -40px; }

.mgb-m50 { margin-bottom: -50px; }

.mgt-m10 { margin-top: -10px; }

.mgt-m20 { margin-top: -20px; }

.mgt-m30 { margin-top: -30px; }

.mgt-m40 { margin-top: -40px; }

.mgt-m50 { margin-top: -50px; }

.gutter-5px { margin-left: -2.5px !important; margin-right: -2.5px !important; }
.gutter-5px > li, .gutter-5px > div { padding-left: 2.5px !important; padding-right: 2.5px !important; }

.gutter-10px { margin-left: -5px !important; margin-right: -5px !important; }
.gutter-10px > li, .gutter-10px > div { padding-left: 5px !important; padding-right: 5px !important; }

.gutter-20px { margin-left: -10px !important; margin-right: -10px !important; }
.gutter-20px > li, .gutter-20px > div { padding-left: 10px !important; padding-right: 10px !important; }

.gutter-30px { margin-left: -15px !important; margin-right: -15px !important; }
.gutter-30px > li, .gutter-30px > div { padding-left: 15px !important; padding-right: 15px !important; }

.gutter-40px { margin-left: -20px !important; margin-right: -20px !important; }
.gutter-40px > li, .gutter-40px > div { padding-left: 20px !important; padding-right: 20px !important; }

.gutter-50px { margin-left: -25px !important; margin-right: -25px !important; }
.gutter-50px > li, .gutter-50px > div { padding-left: 25px !important; padding-right: 25px !important; }

.gutter-vr-5px { margin-top: -2.5px !important; margin-bottom: -2.5px !important; }
.gutter-vr-5px > li, .gutter-vr-5px > div { padding-top: 2.5px !important; padding-bottom: 2.5px !important; }

.gutter-vr-10px { margin-top: -5px !important; margin-bottom: -5px !important; }
.gutter-vr-10px > li, .gutter-vr-10px > div { padding-top: 5px !important; padding-bottom: 5px !important; }

.gutter-vr-20px { margin-top: -10px !important; margin-bottom: -10px !important; }
.gutter-vr-20px > li, .gutter-vr-20px > div { padding-top: 10px !important; padding-bottom: 10px !important; }

.gutter-vr-30px { margin-top: -15px !important; margin-bottom: -15px !important; }
.gutter-vr-30px > li, .gutter-vr-30px > div { padding-top: 15px !important; padding-bottom: 15px !important; }

.gutter-vr-40px { margin-top: -20px !important; margin-bottom: -20px !important; }
.gutter-vr-40px > li, .gutter-vr-40px > div { padding-top: 20px !important; padding-bottom: 20px !important; }

.gutter-vr-50px { margin-top: -25px !important; margin-bottom: -25px !important; }
.gutter-vr-50px > li, .gutter-vr-50px > div { padding-top: 25px !important; padding-bottom: 25px !important; }

.gutter-vr-80px { margin-top: -40px !important; margin-bottom: -40px !important; }
.gutter-vr-80px > li, .gutter-vr-80px > div { padding-top: 40px !important; padding-bottom: 40px !important; }

.gutter-vr-90px { margin-top: -45px !important; margin-bottom: -45px !important; }
.gutter-vr-90px > li, .gutter-vr-90px > div { padding-top: 45px !important; padding-bottom: 45px !important; }

.gutter-vr-100px { margin-top: -50px !important; margin-bottom: -50px !important; }
.gutter-vr-100px > li, .gutter-vr-100px > div { padding-top: 50px !important; padding-bottom: 50px !important; }

/** 02.03 Colors */
.tc-default, .tc-default a { color: #415076 !important; }
.tc-alternet, .tc-alternet a { color: #415076 !important; }
.tc-primary, .tc-primary a { color: #16a3fe !important; }
.tc-secondary, .tc-secondary a { color: #23c99d !important; }
.tc-info, .tc-info a { color: #026bfb !important; }
.tc-warning, .tc-warning a { color: #f6be26 !important; }
.tc-success, .tc-success a { color: #39d884 !important; }
.tc-danger, .tc-danger a { color: #e6604a !important; }
.tc-light, .tc-light a:not(.btn) { color: #f6fafd !important; }
.tc-light a:not(.btn):hover { color: #16a3fe !important; }
.tc-light p, .tc-light a:not(.btn) p, .tc-light .list, .tc-light a:not(.btn) .list { color: #b9d2f4; }
.tc-dark, .tc-dark a { color: #0b0c10 !important; }
.tc-theme, .tc-theme a { color: #16a3fe !important; }
.tc-theme-alt, .tc-theme-alt a { color: #122272 !important; }
.tc-white { color: #fff; }

.bg-default { background-color: #415076 !important; }
.bg-alternet { background-color: #122272 !important; }
.bg-primary { background-color: #16a3fe !important; }
.bg-secondary { background-color: #23c99d !important; }
.bg-light { background-color: #f6fafd !important; }
.bg-light-alt { background-color: #f1f4f9 !important; }
.bg-dark { background-color: #0b0c10 !important; width: 100vw;
  }
.bg-dark-alt { background-color: #15171f !important; }
.bg-trans { background-color: transparent !important; }
.bg-grad { background-image: linear-gradient(90deg, #0e5d8f 0%, #9b6eb7 100%) }
.bg-info { background-color: #026bfb !important; }
.bg-warning { background-color: #f6be26 !important; }
.bg-success { background-color: #39d884 !important; }
.bg-danger { background-color: #e6604a !important; }
.bg-black { background-color: #000; }
.bg-black-2, .bonus-info { background-color: rgba(0, 0, 0, 0.02); }
.bg-black-5 { background-color: rgba(0, 0, 0, 0.05); }
.bg-black-7 { background-color: rgba(0, 0, 0, 0.07); }
.bg-black-10 { background-color: rgba(0, 0, 0, 0.1); }
.bg-black-14 { background-color: rgba(0, 0, 0, 0.14); }
.bg-black-22 { background-color: rgba(0, 0, 0, 0.22); }
.bg-white { background-color: #fff; }
.bg-white-2 { background-color: rgba(255, 255, 255, 0.02); }
.bg-white-5 { background-color: rgba(255, 255, 255, 0.05); }
.bg-white-7 { background-color: rgba(255, 255, 255, 0.07); }
.bg-white-10 { background-color: rgba(255, 255, 255, 0.1); }
.bg-white-14 { background-color: rgba(255, 255, 255, 0.14); }
.bg-white-22 { background-color: rgba(255, 255, 255, 0.22); }
.bg-theme { background-color: #122272; }
.bg-theme-alt { background-color: #1940b0; }
.bg-theme-grad { background-image: linear-gradient(90deg, #122272 0%, #1940b0 100%); }
.bg-theme-grad-alt { background-image: linear-gradient(90deg, #1940b0 0%, #122272 100%); }
.bg-theme-dark { background-color: #0c195a; }
.bg-theme-dark-alt { background-color: #132582; }
.bg-theme-light { background-color: #f6fafd; }
.bg-theme-light-alt { background-color: #eaeff6; }

.theme-dark { background: #0c195a; }

/** 02.04 Misc */
.ttc { text-transform: capitalize; }
.ttu { text-transform: uppercase; }
.ttl { text-transform: lowercase; }
.ttn { text-transform: none; }

.fw-7 { font-weight: 700 !important; }
.fw-6 { font-weight: 600 !important; }
.fw-5 { font-weight: 500 !important; }
.fw-4 { font-weight: 400 !important; }
.fw-3 { font-weight: 300 !important; }
.fw-rg { font-weight: 400 !important; }
.fw-bold { font-weight: 700 !important; }
.fw-md { font-weight: 500 !important; }
.fw-lt { font-weight: 300 !important; }

.ov-h, .nk-block-video, .nk-banner, .banner-wrap, .feature-group, .feature-img { overflow: hidden; }
.ov-xh { overflow-x: hidden; }
.ov-yh { overflow-y: hidden; }
.ov-s { overflow: scroll; }
.ov-xs { overflow-x: scroll; }
.ov-ys { overflow-y: scroll; }

.bd { border: 1px solid #d1e0f6; }
.bd-2 { border-width: 2px; }
.bd-3 { border-width: 3px; }
.bdt { border-top: 1px solid #d1e0f6; }
.bdt-2 { border-top-width: 2px; }
.bdt-3 { border-top-width: 3px; }
.bdb { border-bottom: 1px solid #d1e0f6; }
.bdb-2 { border-bottom-width: 2px; }
.bdb-3 { border-bottom-width: 3px; }
.bdl { border-left: 1px solid #d1e0f6; }
.bdl-2 { border-left-width: 2px; }
.bdl-3 { border-left-width: 3px; }
.bdr { border-right: 1px solid #d1e0f6; }
.bdr-2 { border-right-width: 2px; }
.bdr-3 { border-right-width: 3px; }
.bd-dashed { border-style: dashed; }
.bd-dotted { border-style: dotted; }
.bd-double { border-style: double; }
.bd-light { border-color: #dbe4f7; }
.bd-lighter { border-color: #dbe4f7; }
.bd-lightest { border-color: #f4f7fd; }
.bd-dark { border-color: #394995; }
.bd-darker { border-color: #394995; }
.bd-darkest { border-color: #394995; }
.bd-primary { border-color: #16a3fe; }
.bd-alternet { border-color: #a2b3cd; }
.bd-secondary { border-color: #122272; }
.bd-info { border-color: #9ac4fd; }
.bd-warning { border-color: #fbe5a8; }
.bd-success { border-color: #b0efce; }
.bd-danger { border-color: #f5bfb7; }

.round { border-radius: 6px !important; }
.round-sm { border-radius: 4px !important; }
.round-md { border-radius: 10px !important; }
.round-lg { border-radius: 15px !important; }
.round-full, .circle { border-radius: 50% !important; }

.bdrs-1 { border-radius: 1px; }

.bdrs-2 { border-radius: 2px; }

.bdrs-3 { border-radius: 3px; }

.bdrs-4 { border-radius: 4px; }

.bdrs-5 { border-radius: 5px; }

.bdrs-6 { border-radius: 6px; }

.bdrs-7 { border-radius: 7px; }

.bdrs-8 { border-radius: 8px; }

.bdrs-9 { border-radius: 9px; }

.bdrs-1x { border-radius: 10px; }

.bdrs-2x { border-radius: 20px; }

.bdrs-3x { border-radius: 30px; }

.bdrs-4x { border-radius: 40px; }

.bdrs-5x { border-radius: 50px; }

.bdrs-15x { border-radius: 15px; }

.bdrs-25x { border-radius: 25px; }

.bdrs-35x { border-radius: 35px; }

.bdrs-45x { border-radius: 45px; }

.shadow { box-shadow: 0px 3px 12px 0px rgba(18, 24, 58, 0.08) !important; }
.shadow-soft { box-shadow: 0px 5px 45px 0px rgba(18, 24, 58, 0.08); }
.shadow-soft-sm { box-shadow: 0px 2px 12px 0px rgba(18, 24, 58, 0.08); }
.shadow-dark { box-shadow: 0 5px 40px rgba(18, 24, 58, 0.15); }
.shadow-dark-sm { box-shadow: 0 3px 12px 0px rgba(18, 24, 58, 0.15); }
.shadow-heavy { box-shadow: 0 5px 50px rgba(18, 24, 58, 0.25); }
.shadow-heavy-sm { box-shadow: 0 3px 12px 0px rgba(18, 24, 58, 0.25); }

.nk-df-mask-a, .mask-a:before, .mask-a-bg { background-image: url(../images/mask-a.svg); }
.ie .nk-df-mask-a, .ie .mask-a:before, .ie .mask-a-bg { background-image: url(../images/mask-a.png); }

.nk-df-mask-a-md, .mask-a-md:before, .mask-a-md-bg { background-image: url(../images/mask-a-md.svg); }
.ie .nk-df-mask-a-md, .ie .mask-a-md:before, .ie .mask-a-md-bg { background-image: url(../images/mask-a-md.png); }

.nk-df-mask-a-dark, .mask-a-dark:before, .mask-a-dark-bg { background-image: url(../images/mask-a-dark.svg); }
.ie .nk-df-mask-a-dark, .ie .mask-a-dark:before, .ie .mask-a-dark-bg { background-image: url(../images/mask-a-dark.svg); }

/* .nk-df-mask-line, .mask-line:before, .mask-line-bg { background-image: url(../images/mask-line.svg); }
.ie .nk-df-mask-line, .ie .mask-line:before, .ie .mask-line-bg { background-image: url(../images/mask-line.png); } */

.mask-a:before, .mask-a-md:before, .mask-a-dark:before { background-position: bottom center !important; }

.nk-df-shape-a, .shape-a:after, .shape-a-bg, .bg-shape-a { background-image: url(../images/shape-a.png); }

.nk-df-shape-a-sm, .shape-a-sm:after, .shape-a-sm-bg { background-image: url(../images/shape-a-sm.png); }

.nk-df-shape-b, .shape-b:after, .shape-b-bg, .bg-shape-b { background-image: url(../images/shape-b.png); }

.nk-df-shape-b-sm, .shape-b-sm:after, .shape-b-sm-bg { background-image: url(../images/shape-b-sm.png); }

.nk-df-shape-c, .shape-c:after, .shape-c-bg { background-image: url(../images/shape-c.png); }

.nk-df-shape-c-sm, .shape-c-sm:after, .shape-c-sm-bg { background-image: url(../images/shape-c-sm.png); }

.nk-df-shape-d, .shape-d:after, .shape-d-bg { background-image: url(../images/shape-d.png); }

.nk-df-shape-d-sm, .shape-d-sm:after, .shape-d-sm-bg { background-image: url(../images/shape-d-sm.png); }

.nk-df-shape-e, .shape-e:after, .shape-e-bg { background-image: url(../images/shape-e.png); }

.nk-df-shape-e-sm, .shape-e-sm:after, .shape-e-sm-bg { background-image: url(../images/shape-e-sm.png); }

.nk-df-shape-f, .shape-f:after, .shape-f-bg { background-image: url(../images/shape-f.png); }

.nk-df-shape-f-sm, .shape-f-sm:after, .shape-f-sm-bg { background-image: url(../images/shape-f-sm.png); }

.nk-df-shape-g, .shape-g:after, .shape-g-bg { background-image: url(../images/shape-g.png); }

.nk-df-shape-g-sm, .shape-g-sm:after, .shape-g-sm-bg { background-image: url(../images/shape-g-sm.png); }

.nk-df-shape-h, .shape-h:after, .shape-h-bg { background-image: url(../images/shape-h.png); }

.nk-df-shape-h-sm, .shape-h-sm:after, .shape-h-sm-bg { background-image: url(../images/shape-h-sm.png); }

/* .nk-df-shape-i, .shape-i:after, .shape-i-bg { background-image: url(../images/shape-i.png); } */

.nk-df-shape-i-sm, .shape-i-sm:after, .shape-i-sm-bg { background-image: url(../images/shape-i-sm.png); }

.mode-fullscreen .nk-ovm:after { background-repeat: repeat; background-position: 0 0; background-size: 100%; background-attachment: fixed; }

.nk-ovm { z-index: 1; }
.nk-ovm:before, .nk-ovm:after { bottom: -1px; left: -1px; }
.nk-ovm:before { z-index: 1; }
.nk-ovm-repeat:after { background-repeat: repeat; background-size: contain; }
.nk-ovm-fixed { z-index: -1; }
.nk-ovm-fixed:after { background-attachment: fixed; }

.ovm-top { bottom: auto; }
.ovm-bottom { top: auto; }
.ovm-left { right: auto; }
.ovm-right { left: auto; }
.ovm-h-10 { height: 10%; }
.ovm-h-20 { height: 20%; }
.ovm-h-25 { height: 25%; }
.ovm-h-30 { height: 30%; }
.ovm-h-35 { height: 35%; }
.ovm-h-40 { height: 40%; }
.ovm-h-45 { height: 45%; }
.ovm-h-50 { height: 50%; }
.ovm-h-55 { height: 55%; }
.ovm-h-60 { height: 60%; }
.ovm-h-65 { height: 65%; }
.ovm-h-70 { height: 70%; }
.ovm-h-75 { height: 75%; }
.ovm-h-80 { height: 80%; }
.ovm-h-90 { height: 90%; }
.ovm-h-100 { height: 100%; }
.ovm-w-10 { width: 10%; }
.ovm-w-20 { width: 20%; }
.ovm-w-25 { width: 25%; }
.ovm-w-30 { width: 30%; }
.ovm-w-40 { width: 40%; }
.ovm-w-50 { width: 50%; }
.ovm-w-60 { width: 60%; }
.ovm-w-70 { width: 70%; }
.ovm-w-75 { width: 75%; }
.ovm-w-80 { width: 80%; }
.ovm-w-90 { width: 90%; }
.ovm-w-100 { width: 100%; }

.has-bg-image > div { position: relative; z-index: 1; }

.bg-fixed { background-attachment: fixed; }
.bg-image { position: absolute !important; z-index: 0 !important; opacity: 0; transition: opacity .4s; }
.bg-image.bg-image-loaded { opacity: 1; }
.bg-image img { display: none !important; }
.bg-pattern-dots-color { background-image: url(../images/dot-a.png); background-size: contain; }
.bg-pattern-dots { background-image: url(../images/dot-b.png); background-size: contain; background-repeat: repeat-y; }
.bg-contain { background-size: contain; }
.bg-bottom-center { background-position: 50% 100%; }
.bg-shape-b { background-position: 50% 100%; }

.bg-image.bp-l0, .has-bg.bp-l0 { background-position: 0%; }

.bg-image.bp-r0, .has-bg.bp-r0 { background-position: 0%; }

.bg-image.bp-l10, .has-bg.bp-l10 { background-position: 10%; }

.bg-image.bp-r10, .has-bg.bp-r10 { background-position: 40%; }

.bg-image.bp-l20, .has-bg.bp-l20 { background-position: 20%; }

.bg-image.bp-r20, .has-bg.bp-r20 { background-position: 30%; }

.bg-image.bp-l25, .has-bg.bp-l25 { background-position: 25%; }

.bg-image.bp-r25, .has-bg.bp-r25 { background-position: 25%; }

.bg-image.bp-l30, .has-bg.bp-l30 { background-position: 30%; }

.bg-image.bp-r30, .has-bg.bp-r30 { background-position: 20%; }

.bg-image.bp-l40, .has-bg.bp-l40 { background-position: 40%; }

.bg-image.bp-r40, .has-bg.bp-r40 { background-position: 10%; }

.overlay:after { content: ''; }
.overlay-light:after { background: #fff; }
.overlay-dark:after { background: #000; }
.overlay-theme:after { background: #122272; }
.overlay-theme-dark:after { background: #0c195a; }
.overlay-opacity-10:after { opacity: 0.1; }
.overlay-opacity-15:after { opacity: 0.15; }
.overlay-opacity-20:after { opacity: 0.2; }
.overlay-opacity-25:after { opacity: 0.25; }
.overlay-opacity-30:after { opacity: 0.3; }
.overlay-opacity-35:after { opacity: 0.35; }
.overlay-opacity-40:after { opacity: 0.4; }
.overlay-opacity-45:after { opacity: 0.45; }
.overlay-opacity-50:after { opacity: 0.5; }
.overlay-opacity-55:after { opacity: 0.55; }
.overlay-opacity-60:after { opacity: 0.6; }
.overlay-opacity-65:after { opacity: 0.65; }
.overlay-opacity-70:after { opacity: 0.7; }
.overlay-opacity-75:after { opacity: 0.75; }
.overlay-opacity-80:after { opacity: 0.8; }
.overlay-opacity-85:after { opacity: 0.85; }
.overlay-opacity-90:after { opacity: 0.9; }
.overlay-opacity-95:after { opacity: 0.95; }
.overlay-opacity-100:after { opacity: 1; }

.op-10 { opacity: 0.1; }

.op-20 { opacity: 0.2; }

.op-30 { opacity: 0.3; }

.op-40 { opacity: 0.4; }

.op-50 { opacity: 0.5; }

.op-60 { opacity: 0.6; }

.op-70 { opacity: 0.7; }

.op-80 { opacity: 0.8; }

.op-90 { opacity: 0.9; }

.op-100 { opacity: 1; }

.op-15 { opacity: 0.15; }

.op-25 { opacity: 0.25; }

.op-35 { opacity: 0.35; }

.op-45 { opacity: 0.45; }

.op-55 { opacity: 0.55; }

.op-65 { opacity: 0.65; }

.op-75 { opacity: 0.75; }

.op-85 { opacity: 0.85; }

.op-95 { opacity: 0.95; }

.alignright, .alignleft, .aligncenter, .alignnone { display: block; padding: 0; margin: 15px auto; }

@media (min-width: 576px) { .alignright, .alignleft, .aligncenter, .alignnone { margin: 0 0 15px; display: inline; }
  .alignright { margin-left: 20px; float: right; }
  .alignleft { margin-right: 20px; float: left; }
  .alignnone { margin-right: 20px; }
  .aligncenter { display: block; margin: 15px auto; } }
.wide, .wide-rg, .wide-auto, .wide-auto-rg { max-width: 750px; }
.wide-auto, .wide-auto-rg, .wide-auto-xl, .wide-auto-lg, .wide-auto-md, .wide-auto-sm, .wide-auto-xs { margin: 0 auto; }
.wide-xl, .wide-auto-xl { max-width: 1560px; }
.wide-lg, .wide-auto-lg { max-width: 1140px; }
.wide-md, .wide-auto-md { max-width: 945px; }
.wide-sm, .wide-auto-sm { max-width: 555px; }
.wide-xs, .wide-auto-xs { max-width: 360px; }
.wide-none, .wide-auto-none { max-width: inherit; }

.over-up, [class*="over-up-"] { z-index: 9; }

.over-up { margin-top: -125px; }
.over-up-sm { margin-top: -45px; }
.over-up-md { margin-top: -75px; }
.over-up-lg { margin-top: -165px; }
.over-up-xl { margin-top: -275px; }

@media (min-width: 768px) { .over-up { margin-top: -190px; }
  .over-up-sm { margin-top: -75px; }
  .over-up-md { margin-top: -125px; }
  .over-up-lg { margin-top: -275px; }
  .over-up-xl { margin-top: -350px; } }
.nk-ovm .nk-svgs { z-index: 3; }
.nk-svgs svg { height: 100%; width: auto; }
.nk-svgs.svg-loaded svg { display: none; }

.hr, hr { border-top: 1px solid #dbe4f7; margin-top: 2.5rem; margin-bottom: 2.5rem; }

.section-full { display: flex; align-items: center; min-height: 100vh; }
.section-content { width: 100%; }

.h-110 { height: 110% !important; }
.h-115 { height: 115% !important; }
.h-120 { height: 120% !important; }
.h-125 { height: 125% !important; }
.h-130 { height: 130% !important; }

/** 03. Elements */
/** 03.01 Alert */
.alert { position: relative; font-weight: 400; color: #fff; border: none; }
.alert:last-child { margin-bottom: 0; }
.alert a, .alert .alert-link { color: inherit; font-weight: inherit; box-shadow: 0 1px 0 currentColor; transition: box-shadow .3s; }
.alert a:hover, .alert .alert-link:hover { box-shadow: 0 0 0; }
.alert .close { color: inherit; transition: all .4s; height: 30px; width: 30px; padding: 0; background: none; position: absolute; top: 50%; border-radius: 50%; transform: translateY(-50%); right: 10px; text-shadow: none; opacity: .7; }
.alert .close:not(:disabled):not(.disabled):focus, .alert .close:not(:disabled):not(.disabled):hover, .alert .close:hover, .alert .close:focus { color: currentColor; box-shadow: none; outline: none; opacity: 1; }
.alert .close:after { position: absolute; font-family: 'themify'; content: '\e646'; top: 0; left: 0; color: currentColor; font-size: 12px; line-height: 30px; text-align: center; width: 100%; }
.alert .close span { display: none; }
.alert-primary { color: #016aad; background: #b9e3ff; }
.alert-primary .close { background: #7ccbfe; }
.alert-primary-alt { background: #16a3fe; }
.alert-primary-alt .close { background: #0179c7; }
.alert-secondary { color: #147259; background: #a1efda; }
.alert-secondary .close { background: #aef1df; }
.alert-secondary-alt { background: #23c99d; }
.alert-secondary-alt .close { background: #18886a; }
.alert-success { color: #29cf77; background: #cef5e1; }
.alert-success .close { background: #64e09e; }
.alert-success-alt { background: #39d884; }
.alert-success-alt .close { background: #25b96b; }
.alert-danger { color: #e6604a; background: #f9d6d0; }
.alert-danger .close { background: #f2afa4; }
.alert-danger-alt { background: #e6604a; }
.alert-danger-alt .close { background: #9b2815; }
.alert-info { color: #026bfb; background: #cae0ff; }
.alert-info .close { background: #65a6fe; }
.alert-info-alt { background: #026bfb; }
.alert-info-alt .close { background: #014096; }
.alert-warning { color: #e0a609; background: #fef4db; }
.alert-warning .close { background: #f9d46f; }
.alert-warning-alt { background: #f6be26; }
.alert-warning-alt .close { background: #e0a609; }
.bt-started{
	    position: relative;
    width: auto;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 14px;
    background-color: #05b169;
    margin: 0px;
    border-radius: 4px;
    transition: all 80ms ease-in-out 0s;
    padding: 12px 18px;
    border-width: 1px;
    border-style: solid;
    border-color: #05b169;
    border-image: initial;
}

/** 03.02 Buttons */
.btn { position: relative; font-size: 12px; line-height: 24px; font-weight: 500; padding: 8px 15px; letter-spacing: 0.1em; text-align: center; text-transform: uppercase; color: #fff; min-width: 120px; max-width: 100%; border: 2px solid #16a3fe; box-sizing: border-box; transition: all 0.5s; position: relative; border-radius: 5px; z-index: 2; display: inline-flex; align-items: center; justify-content: center; }
.btn .icon { color: inherit; }
.btn-grad { border: none; background-image: linear-gradient(to right, #16a3fe 0%, #23c99d 100%); }
.btn-grad:before { position: absolute; left: 0; top: 0; content: ""; width: 100%; height: 100%; z-index: -2; border-radius: 5px; opacity: 0; transition: all .5s; background-image: linear-gradient(to right, #23c99d 0%, #16a3fe 100%); }
.btn-grad:hover:before { opacity: 1; }
.btn-grad:hover, .btn-grad:focus { color: #fff; }
.btn:hover, .btn:focus { color: #fff; outline: none; box-shadow: none; }
.btn .icon { transition: all .5s; }
.btn span { position: relative; z-index: 999999; display: block; }
.btn span + .icon { margin-left: 10px; }
.btn .icon + span { margin-left: 10px; }
.btn .badge { position: absolute; top: 0; right: 0; transform: translate(50%, -50%); }
.btn-md { font-size: 12px; line-height: 24px; padding: 10px 26px; }
.btn-sm { font-size: 11px; line-height: 23px; padding: 8px 20px; }
.btn-auto { min-width: 0; }
.btn-grp { display: inline-flex; flex-wrap: wrap; align-items: center; margin: -7px; }
.btn-grp > div, .btn-grp > li { padding: 7px; }
.btn-grp-break > div, .btn-grp-break > li { width: 100%; text-align: center; }
.btn-download { display: inline-block; height: 40px; }
.btn-download img { height: 100%; }
.btn-outline:not(:hover) { color: #415076; background-color: transparent !important; }
.btn-outline:before { background-image: none; background-color: #fff; opacity: 1; margin: 2px; width: calc(100% - 4px); height: calc(100% - 4px); }
.btn-outline.on-bg-light:before { background-color: #f6fafd; }
.btn-outline.on-bg-light-alt:before { background-color: #f1f4f9; }
.btn-outline.on-bg-theme, .btn-outline.on-bg-theme-alt, .btn-outline.on-bg-theme-dark { color: #fff; }
.btn-outline.on-bg-theme:before { background-color: #122272; }
.btn-outline.on-bg-theme-alt:before { background-color: #1940b0; }
.btn-outline.on-bg-theme-dark:before { background-color: #0c195a; }
.btn-outline:hover:before { opacity: 0; }
.btn-outline.btn-grad { border: none; }
.btn-outline.btn-grad:hover { color: #fff; }
.btn-icon { min-width: 44px; padding-left: 6px; padding-right: 6px; }
.btn-primary { background-color: #16a3fe; border-color: #16a3fe; }
.btn-primary:hover { background-color: #23c99d; border-color: #23c99d; }
.btn-primary.btn-outline:hover { background-color: #16a3fe; border-color: #16a3fe; }
.btn-secondary { background-color: #23c99d; border-color: #23c99d; }
.btn-secondary:hover { background-color: #16a3fe; border-color: #16a3fe; }
.btn-secondary.btn-outline:hover { background-color: #23c99d; border-color: #23c99d; }
.btn-info { background-color: #026bfb; border-color: #026bfb; }
.btn-info:hover { background-color: #014baf; border-color: #014baf; }
.btn-info.btn-outline:hover { background-color: #026bfb; border-color: #026bfb; }
.btn-warning { background-color: #f6be26; border-color: #f6be26; }
.btn-warning:hover { background-color: #c79408; border-color: #c79408; }
.btn-warning.btn-outline:hover { background-color: #f6be26; border-color: #f6be26; }
.btn-success { background-color: #39d884; border-color: #39d884; }
.btn-success:hover { background-color: #20a45f; border-color: #20a45f; }
.btn-success.btn-outline:hover { background-color: #39d884; border-color: #39d884; }
.btn-danger { background-color: #e6604a; border-color: #e6604a; }
.btn-danger:hover { background-color: #c8341c; border-color: #c8341c; }
.btn-danger.btn-outline:hover { background-color: #e6604a; border-color: #e6604a; }
.btn-dark { background-color: #415076; border-color: #415076; }
.btn-dark:hover { background-color: #6e80af; border-color: #6e80af; }
.btn-dark.btn-outline:hover { background-color: #415076; border-color: #415076; }
.btn-light { background-color: #f6fafd; border-color: #f6fafd; color: #415076 !important; }
.btn-light:hover { background-color: #cce3f4; border-color: #cce3f4; color: #415076 !important; }
.btn-light.btn-outline { background-color: #cce3f4; border-color: #cce3f4; }
.btn-light.btn-outline:hover { background-color: #cce3f4; border-color: #cce3f4; }
.btn-facebook { background-color: #3b5998; border-color: #3b5998; }
.btn-facebook:hover { background-color: #2d4373; border-color: #2d4373; }
.btn-facebook.btn-outline .icon { color: #3b5998; }
.btn-facebook.btn-outline:hover { background-color: #3b5998; border-color: #3b5998; }
.btn-facebook.btn-outline:hover .icon { color: #fff; }
.btn-google { background-color: #d85040; border-color: #d85040; }
.btn-google:hover { background-color: #be3727; border-color: #be3727; }
.btn-google.btn-outline .icon { color: #d85040; }
.btn-google.btn-outline:hover { background-color: #d85040; border-color: #d85040; }
.btn-google.btn-outline:hover .icon { color: #fff; }
.btn-play { color: #fff; height: 60px; width: 60px; font-size: 18px; text-align: center; line-height: 58px; border-radius: 50%; display: inline-block; border: 1px solid currentColor; }
.btn-play-sm { height: 40px; width: 40px; }
.btn-play:after { position: absolute; content: ""; height: calc(100% + 16px); width: calc(100% + 16px); top: -8px; left: -8px; border-radius: 50%; border: 1px solid rgba(246, 250, 253, 0.35); animation: 1s ease 0s normal none infinite running playbutton; }
.btn-play:hover { color: #23c99d !important; }
.btn-play-s2 { background-image: linear-gradient(to right, #16a3fe 0%, #23c99d 100%); border: none; }
.btn-play-s2:before { position: absolute; height: calc(100% - 4px); width: calc(100% - 4px); top: 2px; left: 2px; content: ''; background: #fff; border-radius: 50%; z-index: -1; }
.btn-play-s3 { background-color: #fff; border: none; }
.btn-play-icon { height: 20px; width: 14px; background: linear-gradient(to right, #16a3fe 0%, #23c99d 100%); display: block; position: absolute; top: 50%; left: 50%; transform: translate(-40%, -50%); }
.btn-play-icon:before, .btn-play-icon:after { position: absolute; content: ''; height: 0; width: 0; right: 0; }
.btn-play-icon:before { top: 0; border-right: 14px solid #fff; border-bottom: 10px solid transparent; }
.btn-play-icon:after { bottom: 0; border-right: 14px solid #fff; border-top: 10px solid transparent; }
.btn-play-icon-s2 { height: 34px; width: 24px; }
.btn-play-icon-s2 path { fill: #16a3fe; }
.btn-play-dark { color: #415076; }
.btn-play-dark:hover { color: #415076; }
.btn-link { color: #16a3fe; text-decoration: none; }
.btn-link:hover, .btn-link:focus { color: #16a3fe; text-decoration: none; box-shadow: 0 1px 0 currentColor; }

@keyframes playbutton { 0% { transform: scale(0.75); opacity: 1; }
  100% { transform: scale(1.3); opacity: 0; } }
@media (min-width: 576px) { .btn { font-size: 13px; line-height: 25px; padding: 12px 30px; }
  .btn.btn-grad { padding: 14px 30px; }
  .btn-md { font-size: 12px; line-height: 24px; padding: 8px 26px; }
  .btn-md.btn-grad { padding: 10px 30px; }
  .btn-sm { font-size: 12px; line-height: 24px; padding: 3px 20px; }
  .btn-sm.btn-grad { padding: 5px 30px; }
  .btn-play { height: 80px; width: 80px; font-size: 18px; line-height: 78px; }
  .btn-play-sm { height: 60px; width: 60px; line-height: 58px; }
  .btn-icon { padding-left: 6px; padding-right: 6px; }
  .btn-grp { margin: -15px; }
  .btn-grp > div, .btn-grp > li { padding: 15px; } }
.badge { color: #fff; background-color: #16a3fe; display: inline-block; font-weight: 600; text-transform: uppercase; border-radius: 20px; padding: 5px 16px; font-size: 13px; line-height: 24px; border: 1px solid #16a3fe; }
.badge:not(.badge-outline) { color: #fff; }
.badge-primary { background-color: #16a3fe; border-color: #16a3fe; color: #16a3fe; }
.badge-secondary { background-color: #23c99d; border-color: #23c99d; color: #23c99d; }
.badge-info { background-color: #026bfb; border-color: #026bfb; color: #026bfb; }
.badge-warning, .badge-hot { background-color: #f6be26; border-color: #f6be26; color: #f6be26; }
.badge-success, .badge-new { background-color: #39d884; border-color: #39d884; color: #39d884; }
.badge-danger { background-color: #e6604a; border-color: #e6604a; color: #e6604a; }
.badge-dark { background-color: #0b0c10; border-color: #0b0c10; color: #0b0c10; }
.badge-light { background-color: #cce3f4; border-color: #cce3f4; color: #415076 !important; }
.badge-light.badge-outline { color: #cce3f4; }
.badge-outline { background-color: transparent; }
.badge-md { border-radius: 20px; padding: 5px 16px; font-size: 12px; line-height: 18px; }
.badge-sm { border-radius: 20px; padding: 2px 12px; font-size: 12px; line-height: 18px; }
.badge-xs { border-radius: 10px; padding: 0 8px; font-size: 9px; line-height: 16px; letter-spacing: 0.05em; }

.link { display: inline-flex; align-items: center; font-size: 14px; color: #1940b0; }
.link:hover { color: #16a3fe; }
.link-icon { margin-right: 5px; font-size: 16px; }
.link-icon-sm { font-size: 12px; }
.link span + .link-icon { margin-left: 5px; margin-right: 0; }
.link .icon-circle { margin-right: 10px; }
.link-break { flex-direction: column; }
.link-break .icon-circle { margin-left: 10px; margin-bottom: 10px; }

@media (min-width: 420px) { .btn-grp-break > div, .btn-grp-break > li { width: auto; text-align: left; }
  .link-break { flex-direction: row; }
  .link-break .icon-circle { margin-left: 0; margin-bottom: 0; } }
/** 03.03 Title */
.title { position: relative; font-size: 2.25rem; }
.title:not(:last-child) { margin-bottom: 1.25rem; }
.title small { font-size: 50%; margin-top: .75rem; }
.title-thin { font-weight: 300; }
.title-xxs { font-size: 12px; letter-spacing: 0.1em; }
.title-xxs:not(:last-child) { margin-bottom: .5rem; }
.title-xs { text-transform: uppercase; font-size: 0.88rem; letter-spacing: 0.2em; }
.title-xs small { font-size: 70%; }
.title-sm { font-size: 1.125rem; }
.title-sm:not(:last-child) { margin-bottom: .85rem; }
.title-sm small { font-size: 70%; }
.title-md { font-size: 1.375rem; }
.title-md:not(:last-child) { margin-bottom: 1rem; }
.title-lg { font-size: 1.75rem; }
.title-xl { font-size: 3.5rem; }
.title-light { color: #8ca2e5; }
.title-dark { color: #12183a; }
.title a { color: #1940b0; }
.title a:hover { color: #16a3fe; }
a:hover .title { color: #16a3fe; }
a .title { transition: all .4s; }

/** 03.04 List */
.list li { line-height: 1.44; padding: 5px 0; position: relative; }
.list-check li { padding-left: 24px; }
.list-check li:before { position: absolute; top: 0; left: 0; height: 22px; width: 26px; line-height: 26px; padding: 3px 0; color: #16a3fe; font-family: "Font Awesome 5 Free"; font-weight: 700; font-size: 13px; content: '\f00c'; transition: all .4s; }
.list-dot li { position: relative; padding-left: 16px; }
.list-dot li:before { position: absolute; top: 13px; left: 0; height: 5px; width: 5px; content: ''; border-radius: 50%; background: #16a3fe; }

/** 03.05 Form */
input ~ .error, textarea ~ .error, select ~ .error { position: absolute; right: -10px; top: -15px; background: #e6604a; color: #fff; font-size: 11px; line-height: 18px; padding: 2px 10px; border-radius: 2px; z-index: 9; white-space: nowrap; }
input ~ .error:after, textarea ~ .error:after, select ~ .error:after { position: absolute; content: ''; height: 0; width: 0; border-left: 5px solid transparent; border-right: 5px solid #e6604a; border-bottom: 5px solid transparent; bottom: -5px; left: 3px; }

.field-item { position: relative; padding-bottom: 15px; }
.field-wrap { position: relative; }
.field-wrap + p { margin-top: .75rem; }
.field-label-line { position: absolute; width: 100%; top: 12px; font-size: 0.94rem; left: 0; color: rgba(65, 80, 118, 0.6); z-index: 1; transition: all .5s ease; transform-origin: left; }
.field-inline { border-radius: 6px; display: flex; }
.field-inline input { border-top-right-radius: 0; border-bottom-right-radius: 0; border-right: none; }
.field-inline .btn { border-top-left-radius: 0; border-bottom-left-radius: 0; height: 100%; }
.wgs-search .field-inline .btn { height: auto; }
.field-inline .field-wrap { flex-grow: 1; }
.field-inline .submit-wrap .btn { width: 100%; }
.field-inline-s2 { padding: 10px; display: block; }
.field-inline-s2 .btn { border-top-left-radius: 6px; border-bottom-left-radius: 6px; }

.input-solid { background: none; border: none; width: 100%; padding: 15px 20px; line-height: 23px; }
.input-solid:focus { outline: none; }
.input-line { position: relative; z-index: 2; background: transparent; border: none; width: 100%; font-size: 15px; line-height: 19px; padding: 16px 0 8px; color: #415076; border-bottom: 1px solid #dbe4f7; outline: none; }
.input-textarea { height: 105px; resize: none; outline: none; }
.input-textarea ~ .field-label-line { top: 75px; }
.input-focused .field-label-line { left: 0; top: -10px; transform: scale(0.7); }
.input-bordered { border-radius: 6px; border: 1px solid #dbe4f7; width: 100%; padding: 10px 15px; line-height: 21px; font-size: .9em; color: #8ca2e5; transition: all .4s; background-color: transparent; }
.input-bordered::-webkit-input-placeholder { color: #8ca2e5; }
.input-bordered::-moz-placeholder { color: #8ca2e5; }
.input-bordered:-ms-input-placeholder { color: #8ca2e5; }
.input-bordered:-moz-placeholder { color: #8ca2e5; }
.input-bordered:focus { box-shadow: none; outline: none; border-color: #b7bee3; }
.input-bordered:disabled { background: rgba(219, 228, 247, 0.2); }
.input-switch { position: absolute; height: 1px; width: 1px; opacity: 0; }
.input-switch ~ label:not(.error) { line-height: 20px; padding-top: 5px; padding-bottom: 5px; cursor: pointer; padding-left: 48px; min-height: 30px; min-width: 48px; border-radius: 12px; margin-bottom: 0; display: flex; align-items: center; position: relative; font-size: 14px; color: #415076; }
.input-switch ~ label:not(.error).no-text { padding-left: 56px; }
.input-switch ~ label:not(.error):before, .input-switch ~ label:not(.error):after { position: absolute; content: ''; transition: all .4s; }
.input-switch ~ label:not(.error):before { left: 0; top: 50%; transform: translateY(-50%); height: 20px; width: 40px; border-radius: 15px; background: #d3e0f3; }
.input-switch ~ label:not(.error):after { left: 2px; top: 50%; transform: translateY(-50%); height: 16px; width: 16px; border-radius: 50%; background: #fff; }
.input-switch ~ label:not(.error) span { transition: all .4s; opacity: 1; }
.input-switch ~ label:not(.error) span.over { position: absolute; left: 48px; }
.input-switch ~ label:not(.error) span:last-child { opacity: 0; }
.input-switch:disabled ~ label { opacity: .5; }
.input-switch:checked ~ label:before { background: #16a3fe; }
.input-switch:checked ~ label:after { left: 22px; }
.input-switch:checked ~ label span { opacity: 0; }
.input-switch:checked ~ label span:last-child { opacity: 1; }
.input-switch ~ .error { left: -4px; top: -26px; bottom: auto; right: auto; }
.input-checkbox, .input-radio { position: absolute; height: 1px; width: 1px; opacity: 0; }
.input-checkbox ~ label:not(.error), .input-radio ~ label:not(.error) { position: relative; padding-left: 28px; line-height: 20px; font-size: 14px; cursor: pointer; margin-bottom: 0; }
.input-checkbox ~ label:not(.error):before, .input-checkbox ~ label:not(.error):after, .input-radio ~ label:not(.error):before, .input-radio ~ label:not(.error):after { position: absolute; left: 0; top: 0; height: 20px; width: 20px; line-height: 20px; text-align: center; content: ''; background-color: transparent; border-radius: 3px; }
.input-checkbox ~ label:not(.error):before, .input-radio ~ label:not(.error):before { border: 2px solid #dbe4f7; }
.input-checkbox ~ label:not(.error):after, .input-radio ~ label:not(.error):after { font-family: "Font Awesome 5 Free"; content: '\f00c'; font-weight: 700; font-size: 10px; background-color: #16a3fe; color: #fff; opacity: 0; transition: all .4s; }
.input-checkbox:checked ~ label:not(.error):after, .input-radio:checked ~ label:not(.error):after { opacity: 1; }
.input-checkbox ~ .error, .input-radio ~ .error { left: -4px; top: -26px; bottom: auto; right: auto; }
.input-radio ~ label:not(.error):before, .input-radio ~ label:not(.error):after { border-radius: 50%; }

@media (min-width: 576px) { .field-item { padding-bottom: 30px; } }
@media (min-width: 768px) { .field-inline .submit-wrap .btn { width: auto; }
  .field-inline-s2 { display: flex; } }
.select2-container.select2 { width: 100% !important; }
.select2-container .select2-selection { position: relative; }
.select2-container .select2-selection:focus { outline: none; }
.select2-container .select2-selection .select2-selection__rendered { color: rgba(65, 80, 118, 0.6); font-size: 0.94rem; }
.select2-container .select2-selection .select2-selection__arrow { position: absolute; top: 0; right: 0; height: 100%; width: 40px; }
.select2-container .select2-selection .select2-selection__arrow:after { position: absolute; top: 0; left: 0; height: 100%; width: 100%; font-family: "themify"; content: '\e64b'; text-align: center; line-height: 20px; padding: 10px 0; font-size: .88rem; }
.select2-container .select2-dropdown { border-color: #dbe4f7 !important; }
.select2-container.select-line .select2-selection { height: 44px; line-height: 20px; padding: 16px 40px 8px 0; border-bottom: 1px solid #dbe4f7; }
.select2-container.select-line .select2-selection .select2-selection__rendered { padding-left: 0; }
.select2-container.select-line .select2-selection .select2-selection__arrow:after { padding: 16px 0 8px; }
.select2-container.select-bordered .select2-selection { height: 42px; line-height: 20px; padding: 10px 40px 10px 0; border: 1px solid #dbe4f7; border-radius: 6px; }
.select2-container.select-bordered .select2-selection .select2-selection__rendered { padding-left: 15px; }
.select2-container--below.select2-container--open.select-bordered .select2-selection--single { border-radius: 6px 6px 0 0; }
.select2-container--above.select2-container--open.select-bordered .select2-selection--single { border-radius: 0 0 6px 6px; }
.select2-search { display: none; }
.select2-results { padding: 5px 0; }
.select2-results__option { padding: 6px 15px; font-size: .88rem; transition: all .4s; }
.select2-results__option[aria-selected="true"], .select2-results__option:hover { background: #dbe4f7; }

.select-line.select2-container--open .select2-dropdown--above { border-bottom: 1px solid #dbe4f7 !important; }

/** 03.06 Table */
.table { color: inherit; }
.table td { border-top: none; padding: 18px 0; font-size: 1rem; line-height: 1.5rem; }
.table tr { border-bottom: 1px solid rgba(22, 163, 254, 0.2); }
.table tr:last-child { border-bottom: none; }
.table-head { font-weight: 500; color: #16a3fe; }
.table-token { display: block; margin-bottom: 5px; }
.table-token tbody, .table-token tr, .table-token td { display: block; }
.table-token .table-head { padding-bottom: 2px; }
.table-token .table-des { padding-top: 2px; }
.table-bordered td { padding-left: 20px; padding-right: 20px; border: 1px solid rgba(22, 163, 254, 0.2); }

@media (min-width: 576px) { .table-token { margin-bottom: 1rem; display: table; }
  .table-token tbody { display: table-row-group; }
  .table-token tr { display: table-row; }
  .table-token td { display: table-cell; }
  .table-token .table-head, .table-token .table-des { padding: 18px 0; } }
/** 03.07 Progress */
.progress-list { margin-top: .8rem; }
.progress-wrap { margin-bottom: 1rem; }
.progress-wrap:last-child { margin-bottom: 0; }
.progress-wrap-point { margin-bottom: 78px !important; }
.progress-title { text-transform: uppercase; font-size: 0.75rem; font-weight: 500; display: flex; justify-content: space-between; padding-bottom: .4rem; }
.progress-amount { font-weight: 400; }
.progress-bar { position: relative; width: 100%; height: 20px; border-radius: 4px; }
.progress-bar .progress-percent { border-radius: 4px; }
.progress-bar-xs { height: 6px; border-radius: 2px; }
.progress-bar-xs .progress-percent { border-radius: 2px; border: none; }
.progress-percent { border: 3px solid #1940b0; }
.progress-info { display: block; font-size: 16px; color: #8ca2e5; padding: 12px 0 22px; }
.progress-info span { font-weight: 500; font-size: 18px; color: #23c99d; }
.progress-info-s2 { font-size: 12px; text-transform: uppercase; }
.progress-info-s2 span { font-size: 14px; text-transform: capitalize; }
.progress-point { color: #8ca2e5; font-size: 11px; line-height: 14px; font-weight: 500; text-transform: uppercase; position: absolute; top: 30px; transform: translateX(-50%); padding: 3px 0; }
.progress-point:after { position: absolute; bottom: 100%; left: 50%; margin-left: -1px; content: ''; width: 2px; height: 40px; background: rgba(193, 206, 241, 0.5); }
.progress-point span { display: block; font-size: 12px; color: #16a3fe; }
.progress-point-1 { left: 25%; }
.progress-point-2 { left: 85%; }

ul.progress-info { display: flex; justify-content: space-between; }

/** 03.08 CountDown */
.countdown { display: flex; justify-content: space-around; margin: 0 -5px; }
.countdown-small { justify-content: flex-start; margin: 0 -15px; }
.countdown-s3 { margin: 0 -25px; }
.countdown-item { padding: 0 5px; }
.countdown-s3 .countdown-item { flex-grow: 1; }
.countdown-s3.countdown-small .countdown-item, .countdown-s3-alt .countdown-item { flex-grow: 0; width: auto; }
.countdown-time { position: relative; height: 54px; width: 54px; border-radius: 50%; border: 2px solid #16a3fe; text-align: center; line-height: 50px; display: block; }
.countdown-s2 .countdown-time { border-radius: 6px; background-color: rgba(22, 163, 254, 0.6); border-color: transparent; font-size: 1.6rem; color: #fff; }
.countdown-s3 .countdown-time { border-radius: 0; border: none; font-size: 2.8125rem; line-height: 50px; height: 50px; width: 100%; color: #12183a; }
.countdown-s3 .countdown-time:after { position: absolute; top: calc(50% - 3px); right: -6px; transform: translate(50%, -50%); content: ':'; }
.countdown-s3 .countdown-time-last:after { display: none; }
.countdown-s3-alt .countdown-time { font-size: 1.875rem; padding: 0 20px; font-weight: 300; height: 38px; line-height: 38px; margin-bottom: 0.5rem; }
.countdown-s3.countdown-small .countdown-time { font-size: 1rem; line-height: 24px; height: 24px; text-align: left; padding: 0 20px; }
.countdown-text { display: block; font-weight: 500; font-size: 12px; letter-spacing: 0.01em; color: #8ca2e5; padding-top: 8px; text-align: center; }
.countdown-s2 .countdown-text { text-transform: uppercase; font-weight: 400; color: #16a3fe; }
.countdown-s3 .countdown-text { text-transform: uppercase; color: #16a3fe; }
.countdown-s3-alt .countdown-text { text-transform: capitalize; padding-top: 0; font-weight: 400; }
.countdown-s3.countdown-small .countdown-text { text-align: left; padding: 0 22px; }

.status-countdown { overflow: hidden; }

@media (min-width: 576px) { .status-countdown { min-width: 290px; } }
@media (min-width: 420px) { .countdown-time { height: 64px; width: 64px; line-height: 60px; } }
/** 03.09 Toastr */
/* Toastr */
#toast-container { position: fixed; z-index: 999999; margin-top: 22px; margin-bottom: 16px; }
#toast-container .toast { opacity: 1; }

.toast-top-center { top: 0; right: 0; width: 100%; }

.toast-bottom-center { bottom: 0; right: 0; width: 100%; }

.toast-top-full-width { top: 0; right: 0; width: 100%; }

.toast-bottom-full-width { bottom: 0; right: 0; width: 100%; }

.toast-top-left { top: 0; left: 16px; }

.toast-top-right { top: 0; right: 16px; }

.toast-bottom-right { right: 16px; bottom: 0; }

.toast-bottom-left { bottom: 0; left: 16px; }

.toast-top-center > div, .toast-bottom-center > div { width: 650px; max-width: 90%; margin-left: auto; margin-right: auto; }

.toast-top-full-width > div, .toast-bottom-full-width > div { width: 96%; margin-left: auto; margin-right: auto; }

.toast { background: #fff; color: #fff; overflow: hidden; margin: 0 0 8px; padding: 15px 51px 15px 15px; width: 300px; border-radius: 5px; border: none; display: flex; align-items: center; box-shadow: 0px 2px 18px 2px rgba(18, 24, 58, 0.25); background: #0b0c10; position: relative; }
.toast-close-button { cursor: pointer; position: absolute; text-align: left; right: 15px; text-indent: -9999em; overflow: hidden; background: none; border-radius: 50%; border: none; height: 36px; width: 36px; transition: all .3s; }
.toast-close-button:after { position: absolute; content: '\e646'; font-family: 'themify'; top: 0; right: -1px; text-indent: 0; display: block; font-size: 13px; line-height: 38px; height: 36px; width: 36px; text-align: center; color: #fff; transition: all .3s; }
.toast-close-button:hover, .toast-close-button:focus { background: rgba(246, 250, 253, 0.15); box-shadow: none; outline: none; }
.toast-message { position: relative; flex-grow: 1; font-size: 13px; line-height: 17px; display: flex; align-items: center; padding-left: 10px; min-height: 30px; }
.toast-message-icon { display: inline-block; font-size: 14px; margin-right: 15px; margin-left: -10px; text-align: center; height: 30px; width: 30px; line-height: 30px; border-radius: 50%; }
.toast-message-icon.ti-alert:before { position: relative; top: -2px; }
.toast-info .toast-message-icon { background: #026bfb; }
.toast-warning .toast-message-icon { background: #f6be26; }
.toast-success .toast-message-icon { background: #39d884; }
.toast-error .toast-message-icon { background: #e6604a; }

@media (min-width: 576px) { .toast-message { font-size: 14px; line-height: 18px; } }
/** 03.10 Misc */
.img-xs { height: 40px; width: 40px; }

.text-thumb { display: inline-flex; justify-content: center; align-items: center; border-radius: 50%; color: #fff; font-weight: 500; letter-spacing: 0.1em; border: 2px solid #fff; }
.text-thumb-xs { height: 40px; width: 40px; }

.icon { color: #1940b0; display: inline-block; }
.icon-lg { font-size: 50px; }
.icon-round { display: inline-block; text-align: center; border-radius: 50%; background: rgba(22, 163, 254, 0.1); }
.icon-round.icon-lg { height: 90px; width: 90px; line-height: 90px; }
.icon-circle { display: inline-flex; align-items: center; justify-content: center; height: 40px; width: 40px; border-radius: 50%; }
.icon-border { border: 1px solid #8ca2e5; }
.icon-animation { position: relative; }
.icon-animation:after { position: absolute; content: ""; top: -8px; right: -8px; bottom: -8px; left: -8px; border-radius: 50%; border: 1px solid #8ca2e5; animation: 1s ease 0s normal none infinite running playbutton; }

.toggle-wrap { position: relative; }
.toggle-tigger { display: inline-block; }
.toggle-class { visibility: hidden; opacity: 0; transition: all .4s; }
.toggle-class.active { visibility: visible; opacity: 1; }
.toggle-drop { position: absolute; left: 50%; top: 100%; transform: translateX(-50%); margin-top: -10px; }
.toggle-drop-left { left: 0; transform: translateX(0); }
.toggle-drop-right { left: auto; right: 0; transform: translateX(0); }
.toggle-drop.active { margin-top: 0; }

.drop-list { min-width: 160px; max-width: 160px; background: #fff; padding: 10px 0; transition: all .4s; border-radius: 4px; box-shadow: 0px 3px 12px 0px rgba(18, 24, 58, 0.08); }
.drop-list a { font-size: 13px; line-height: 18px; color: #415076; padding: 7px 20px; display: block; position: relative; }
.drop-list a .badge { margin-left: 5px; position: relative; top: -1px; }
.drop-list a:hover { color: #16a3fe; }

.breadcumb { margin: 0 -13px; }
.breadcumb li { display: inline-block; padding: 0 15px; font-size: .75rem; line-height: 18px; position: relative; }
.breadcumb li:not(:last-child):after { position: absolute; top: 0; right: -3px; content: '-'; line-height: 18px; color: #16a3fe !important; }
.breadcumb li a { color: #16a3fe !important; }
.breadcumb li a:hover:not([class]) { color: #23c99d !important; }

.preloader { position: fixed !important; top: 0; left: 0; right: 0; bottom: 0; z-index: 10000; }
.preloader:before, .preloader:after { position: absolute; left: 0; height: 51%; width: 100%; transition: all .6s ease; background: #fff; content: ''; }
.preloader:before { top: 0; }
.page-loaded .preloader:before { transform: translate(0, -100%); }
.preloader:after { bottom: 0; }
.page-loaded .preloader:after { transform: translate(0, 100%); }
.preloader.no-default { background-color: #122272; }
.page-loaded .preloader.no-default:before, .page-loaded .preloader.no-default:after { transform: translate(0, 0); }
.preloader.no-default:before, .preloader.no-default:after { position: absolute; top: 0; left: 0; height: 100%; transition: all .6s; background-color: #122272; }

.spinner { display: block; position: relative; left: 50%; top: 50%; width: 70px; height: 70px; margin: -35px 0 0 -35px; z-index: 1001; }
.spinner-round { border-radius: 50%; border: 2px solid transparent; border-top-color: #16a3fe; animation: spin 2s linear infinite; }
.spinner-round:before, .spinner-round:after { content: ""; position: absolute; border-radius: 50%; border: 3px solid transparent; }
.spinner-round:before { top: 5px; left: 5px; right: 5px; bottom: 5px; border-top-color: #23c99d; animation: spin 3s linear infinite; }
.spinner-round:after { top: 15px; left: 15px; right: 15px; bottom: 15px; border-top-color: #122272; animation: spin 1.5s linear infinite; }

@keyframes spin { 0% { -webkit-transform: rotate(0deg); -ms-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); -ms-transform: rotate(360deg); transform: rotate(360deg); } }
.text-zoom { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1; text-align: center; text-transform: uppercase; font-weight: 700; height: 100px; width: 100px; }
.text-item { position: absolute; top: 0; bottom: 0; left: 0; right: 0; }
.text-item:nth-child(2) > * { animation-delay: 1s; }
.text-item:nth-child(3) > * { animation-delay: 2s; }
.text-item:nth-child(4) > * { animation-delay: 3s; }
.text-item:nth-child(5) > * { animation-delay: 4s; }
.text-item:nth-child(6) > * { animation-delay: 5s; }
.text-item:nth-child(7) > * { animation-delay: 6s; }
.text-item:nth-child(8) > * { animation-delay: 7s; }
.text-item:nth-child(9) > * { animation-delay: 8s; }
.text-small, .text-large { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); transform-origin: center center; opacity: 0; }
.text-small { color: #97afd5; z-index: 2; font-size: 60px; animation: textzoom9 9s linear infinite; }
.text-large { color: #19243e; z-index: 0; font-size: 120px; animation: textzoom9l 9s linear infinite; }

@keyframes textzoom9 { 0% { transform: translate(-50%, -50%) scale(0); opacity: 0; }
  6% { transform: translate(-50%, -50%) scale(1); opacity: 1; }
  10%, 100% { transform: translate(-50%, -50%) scale(1.3); opacity: 0; } }
@keyframes textzoom9l { 0% { transform: translate(-50%, -50%) scale(0.2); opacity: 0; }
  8% { transform: translate(-50%, -50%) scale(1.2); opacity: 1; }
  10%, 100% { transform: translate(-50%, -50%) scale(1.7); opacity: 0; } }
.modal-dialog-bottom { display: flex; align-items: flex-end; min-height: calc(100% - (.5rem * 2)); }
.modal.fade .modal-dialog-bottom { -webkit-transform: translate(0, 25%); transform: translate(0, 25%); }
.modal.show .modal-dialog-bottom { -webkit-transform: translate(0, -30px); transform: translate(0, -30px); }
.modal-content { background: none; border: none; margin-left: auto; margin-right: auto; width: auto; max-width: 100%; }
.modal-body { background: #fff; border-radius: 6px; }

.modal-close { position: absolute; cursor: pointer; top: -15px; right: -15px; height: 36px; width: 36px; line-height: 39px; padding: 0; text-align: center; z-index: 9999; border-radius: 50%; color: #415076; opacity: 1; background: #fff; box-shadow: 0px 2px 7px 0px rgba(65, 80, 118, 0.2); }

.mfp-close-btn-in .mfp-close { cursor: pointer; top: -15px; right: -15px; height: 36px; width: 36px; line-height: 37px; padding: 0; text-align: center; z-index: 9999; border-radius: 50%; color: #415076 !important; opacity: 1; background: #fff; box-shadow: 0px 2px 7px 0px rgba(65, 80, 118, 0.2); }
.mfp-close-btn-in .mfp-close:hover { text-decoration: none; color: #16a3fe !important; }
.tc-light .mfp-close-btn-in .mfp-close:hover { color: #16a3fe !important; }

.image-popup .mfp-close { cursor: pointer; top: 22px; right: -18px; }

.owl-nav { margin: 20px -10px 0; }
.carousel-nav-center .owl-nav { display: flex; justify-content: center; }
.owl-nav button { border: 1px solid #b9d2f4 !important; border-radius: 50% !important; height: 40px; width: 40px; display: inline-flex; align-items: center; justify-content: center; margin: 0 10px; transition: all .4s; }
.owl-nav button:focus { box-shadow: none; outline: none; }
.owl-nav button.disabled { opacity: 0.4; }

[data-navs="1"], [data-navs="true"] { padding-bottom: 40px; }

.slick-arrow { position: absolute; bottom: 0; height: 40px; width: 40px; margin: 0 10px; cursor: pointer; display: inline-flex; align-items: center; justify-content: center; border-radius: 50% !important; color: #b9d2f4; background-color: transparent; border: 1px solid #b9d2f4 !important; transition: all .4s; }
.slick-arrow:hover { color: #b9d2f4; }
.slick-arrow:focus { box-shadow: none; outline: none; }
.slick-slide { outline: none; }
.slick-disabled { opacity: 0.4; cursor: default; }
.slick-prev { right: 50%; }
.slick-next { left: 50%; }

/** 04. Components */
/** 04.01 Tab */
.tab-nav li a { display: block; padding: 12px 12px; line-height: 19px; font-weight: 400; color: #8ca2e5; border-bottom: 1px solid rgba(219, 228, 247, 0.5); transition: all .4s; }
.tab-nav li a:hover, .tab-nav li a.active { color: #1940b0; }
.tab-nav li a .fas, .tab-nav li a .far { margin-right: 10px; }
.tab-nav-sap { flex-grow: 1; height: 2px; background: #8ca2e5; align-self: center; }
.tab-nav-vr { display: block; }
.tab-nav-vr li:not(:last-child) a { border-bottom: 1px solid rgba(219, 228, 247, 0.5); }
.tab-nav-vr li:last-child a { border: none; }
.tab-nav-vr li a { padding: 18px 20px; }
.tc-light .tab-nav-vr li:not(:last-child) a { border-color: rgba(255, 255, 255, 0.07); }
.bg-theme .tab-nav-vr li:not(:last-child) a { border-color: rgba(19, 60, 142, 0.5); }
.tab-nav-s2 { margin: 0 -15px 15px; }
.tab-nav-s2 li { padding: 0 15px; }
.tab-nav-s2 li a { text-transform: uppercase; padding: 12px 5px; border-bottom-color: transparent; }
.tab-nav-s2 li a:hover, .tab-nav-s2 li a.active { color: #16a3fe; border-bottom-color: #16a3fe; }
.tab-nav-s2 li a .fas, .tab-nav-s2 li a .far { margin-right: 10px; }
.tab-nav-s3 li a { border: none !important; border-radius: 3px; cursor: pointer; padding: 28px 30px; }
.tab-nav-s3 li a [class*=title] { color: #12183a; transition: all .4s; }
.tab-nav-s3 li a p { color: #415076; line-height: 1.6; }
.tab-nav-s3 li a.active { box-shadow: 0px 3px 12px 0px rgba(18, 24, 58, 0.08); background: #fff; cursor: default; }
.tab-nav-s3 li a.active [class*=title] { color: #16a3fe; }
.tab-nav-center { justify-content: center; }
.tab-nav-bdr { border: 1px solid #dbe4f7; border-radius: 5px; }
.tc-light .tab-nav-bdr, .tc-light a:not(.btn) .tab-nav-bdr { border-color: rgba(255, 255, 255, 0.07); }
.bg-theme .tab-nav-bdr { border-color: #133c8e; background: #132582 !important; }
.tc-light .tab-nav-bdr, .tc-light a:not(.btn) .tab-nav-bdr { background: rgba(255, 255, 255, 0.02); }
.tab-nav-line { margin-bottom: 20px; border-bottom: 2px solid rgba(219, 228, 247, 0.5); text-align: center; }
.tab-nav-line li a { border-bottom: none; position: relative; }
.tab-nav-line li a:after { position: absolute; width: 100%; height: 2px; left: 0; bottom: -2px; content: ''; background: #16a3fe; opacity: 0; transition: all .4s; }
.tab-nav-line li a:hover, .tab-nav-line li a.active { color: #16a3fe; }
.tab-nav-line li a.active:after { opacity: 1; }
.tab-nav-line > li { flex-grow: 1; }
.tc-light .tab-nav li a:hover, .tc-light .tab-nav li a.active { color: #16a3fe !important; }
.tab-nav-btn { margin: -7px -7px 8px; }
.tab-nav-btn li:not(.tab-nav-sap) { padding: 7px; }
.tab-nav-btn li a { border-radius: 3px; color: #415076; background: rgba(18, 34, 114, 0.1); border-color: transparent; }
.tab-nav-btn li a:hover, .tab-nav-btn li a.active { color: #16a3fe !important; background: rgba(22, 163, 254, 0.1); }
.tab-nav-btn-s2 li a:hover, .tab-nav-btn-s2 li a.active { color: #fff !important; background: #16a3fe; }
.tab-nav-btn-bdr { margin: -7px; }
.tab-nav-btn-bdr li:not(.tab-nav-sap) { padding: 7px; }
.tab-nav-btn-bdr li a { border-radius: 6px; color: #415076; background: transparent; border: 2px solid !important; border-color: #12183a; }
.tab-nav-btn-bdr li a:hover, .tab-nav-btn-bdr li a.active { color: #16a3fe !important; background: transparent; border-color: #16a3fe; }
.tab-pane { padding: 10px 0 0; }

/** 04.02 Accordion */
.accordion-item { transition: all .4s; padding-bottom: 8px; }
.accordion-item:not(:last-child) { border-bottom: 1px solid #dbe4f7; }
.tc-light .accordion-item:not(:last-child) { border-color: rgba(255, 255, 255, 0.05); }
.bg-theme .accordion-item:not(:last-child) { border-color: #133c8e; }
.accordion-item-s2 { border-width: 2px !important; border: 2px solid #dbe4f7; border-radius: 6px; }
.accordion-item-s2.current { border-color: rgba(18, 34, 114, 0.5); }
.accordion-item-s2:not(:last-child) { margin-bottom: 15px; }
.accordion-item-s3 { border: none !important; border-radius: 6px; background: #fff; box-shadow: 0px 4px 6px 0px rgba(18, 34, 114, 0.07); }
.accordion-item-s3:not(:last-child) { margin-bottom: 30px; }
.accordion-item-s4 { background: rgba(18, 34, 114, 0.09); }
.accordion-item-s4.current { background: rgba(18, 34, 114, 0.05); }
.accordion-title { position: relative; color: #1940b0; font-weight: 400; cursor: pointer; padding: 22px 46px 14px 0; margin-bottom: 0; }
.accordion-item-s2 .accordion-title, .accordion-item-s3 .accordion-title { padding: 25px 46px 17px 30px; }
.accordion-item-s4 .accordion-title { padding: 16px 46px 8px 30px; }
.tc-light .accordion-title, .tc-light a:not(.btn) .accordion-title { color: #16a3fe; }
.accordion-title-sm { font-size: 16px; }
.accordion-title.collapsed { color: #415076; }
.tc-light .accordion-title.collapsed { color: #fff; }
.accordion-title.collapsed .accordion-icon:before { opacity: 0; }
.accordion-title.collapsed .accordion-icon:after { opacity: 1; }
.accordion-title.collapsed .accordion-icon-s2:after { transform: rotate(-180deg); }
.accordion-icon { position: absolute; top: 16px; right: 0; height: 36px; width: 36px; border-radius: 50%; background-color: #f6fafd; }
.accordion-icon:before, .accordion-icon:after { line-height: 36px; color: #1940b0; font-family: "Font Awesome 5 Free"; font-weight: 700; font-size: 16px; text-align: center; transition: all .4s; }
.accordion-icon:before { content: '\f068'; opacity: 1; }
.accordion-icon:after { content: '\f067'; opacity: 0; }
.accordion-icon-s2 { background-color: transparent; }
.accordion-icon-s2:after { font-family: "themify"; content: '\e648'; opacity: 1 !important; }
.accordion-icon-s2:before { display: none; }
.accordion-item-s2 .accordion-icon { right: 12px; top: 18px; }
.accordion-item-s3 .accordion-icon { top: 18px; right: 18px; }
.accordion-item-s4 .accordion-icon { top: 10px; right: 12px; }
.tc-light .accordion-icon, .tc-light a:not(.btn) .accordion-icon { background: rgba(22, 163, 254, 0.1); }
.tc-light .accordion-icon:after, .tc-light .accordion-icon:before { color: #16a3fe; }
.accordion-content { padding-bottom: 12px; padding-right: 46px; }
.accordion-content p:last-child { margin-bottom: 0; }
.accordion-item-s2 .accordion-content, .accordion-item-s3 .accordion-content, .accordion-item-s4 .accordion-content { padding-left: 30px; }
.accordion-item-s4 .accordion-content { padding-bottom: 6px; }
.accordion-faq { margin-top: -15px; }

@media (min-width: 576px) { .accordion-title-sm { font-size: 18px; } }
/** 04.03 Blocks */
.nk-block { display: block; width: 100%; }
.nk-block + .nk-block { padding-top: 80px; }
.nk-block-lg + .nk-block { padding-top: 80px; }
.nk-block-text-head { padding-bottom: 35px; }
.nk-block-img { position: relative; }
.nk-block-img-plx { position: absolute; }
.nk-block-screen { padding: 0 50px 100px 0; }
.nk-block-screen img { border-radius: 3px; border: 1px solid #dfeaf8; }
.nk-block-screen-small { position: absolute; right: 0; bottom: 0; max-width: 210px; }
.nk-block-video { position: relative; border-radius: 4px; }
.nk-block-ca { padding: 70px 20px; }
.nk-block-plx { width: 300px; padding: 30px 10px; margin-left: auto; margin-right: auto; }
.nk-block-contact-s1 { padding: 110px 0; position: relative; display: flex; align-items: center; justify-content: center; background: url(../images/dot-c.png) no-repeat; background-size: contain; background-position: 50% 50%; }
.nk-block-m30 { margin-bottom: -30px; }
.nk-block-m40 { margin-bottom: -40px; }
.nk-block-m50 { margin-bottom: -50px; }
.nk-block .pagination { margin-top: 10px; }
.nk-block-status + .nk-block-actions { padding-top: 80px; padding-bottom: 40px; }

.plx-screen { width: 80px; bottom: 0; left: 0; }
.plx-circle-s1 { width: 80px; top: 10px; left: -5px; z-index: -1; animation: ani_cicle 4s infinite linear; }
.plx-polygon-s1 { width: 70px; bottom: 15px; left: 40px; transform-origin: center; animation: ani_round 20s infinite linear; }
.plx-triangle-s1 { width: 80px; bottom: 0; right: 0; animation: ani_round 30s infinite reverse linear; }

@keyframes ani_round { 0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(10px, 15px) rotate(90deg); }
  50% { transform: translate(0, 0) rotate(180deg); }
  75% { transform: translate(-10px, -15px) rotate(270deg); }
  100% { transform: translate(0, 0) rotate(360deg); } }
@keyframes ani_cicle { 0% { transform: translate(0, 0) rotate(0deg); }
  100% { transform: translate(0, 0) rotate(360deg); } }
.img-block-dot { background-image: url(../images/dot-a.png); background-position: top center; padding: 50px 0; margin-top: -40px; }

@media (min-width: 576px) { .nk-block-ca { padding: 90px 20px; }
  .nk-block-plx { padding: 60px; width: 100%; }
  .nk-block-contact-s1 { padding: 180px 0; }
  .plx-screen { width: 130px; bottom: 0; left: 0; }
  .plx-circle-s1 { width: 140px; top: 10px; left: 10px; }
  .plx-polygon-s1 { width: 100px; bottom: 15px; left: 95px; }
  .plx-triangle-s1 { width: 130px; bottom: 0; right: 20px; } }
@media (min-width: 768px) { .nk-block-ca { padding: 60px; }
  .nk-block-plx { padding: 60px; }
  .plx-screen { width: 160px; bottom: 0; left: 0; }
  .plx-circle-s1 { width: 160px; top: 10px; left: 10px; }
  .plx-polygon-s1 { width: 100px; bottom: 15px; left: 125px; }
  .plx-triangle-s1 { width: 130px; bottom: 0; right: 20px; } }
@media (min-width: 992px) { .nk-block + .nk-block { padding-top: 100px; }
  .nk-block-status + .nk-block-actions { padding-top: 70px; padding-bottom: 40px; }
  .nk-block-sm + .nk-block { padding-top: 70px; }
  .nk-block-lg + .nk-block { padding-top: 100px; }
  .nk-block-img.edge-r1 { margin-right: -160px; }
  .nk-block-img.edge-l1 { margin-left: -160px; }
  .nk-block-img.edge-r2 { margin-right: -360px; }
  .nk-block-img.edge-l2 { margin-left: -360px; }
  .nk-block-img.edge-r3 { margin-right: -500px; }
  .nk-block-img.edge-l3 { margin-left: -500px; }
  .nk-block .pagination { margin-top: 30px; } }
@media (min-width: 1200px) { .nk-block-lg + .nk-block { padding-top: 130px; } }
.app-slide-wrap { position: relative; width: 230px; margin: 15px auto; }
.app-slide-wrap:after { position: absolute; height: calc(100% + 80px); width: calc(100% + 96px); top: 50%; left: 50%; transform: translate(-50%, -50%); content: ''; background-image: url(../images/light-ix.png); }
.app-slide img { border-radius: 4px; }

/** 04.04 Box */
.card { padding: 20px; background: #fff; border: none; box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.02); }
.card-full { height: calc(100% - 30px); }

@media (min-width: 576px) { .card { padding: 30px; }
  .card-lg { padding: 45px 40px; } }
/** 04.05 Banner */
.nk-banner { display: block; width: 100%; z-index: 5; position: relative; }

.banner { position: relative; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center; }
.is-transparent .banner { padding-top: 120px; }
.has-mask .banner { padding-bottom: 75px; }
.banner-screen { position: absolute !important; z-index: 0 !important; left: 50%; right: 0; bottom: -8px; width: 320px; transform: translateX(-50%); }
.banner-wrap { width: 100%; }
.banner-fs .banner-wrap { padding-top: 40px; padding-bottom: 40px; }
.banner-gfx { margin: -15px 0; }
.banner-fs { min-height: auto; }
.is-transparent .banner-fs { min-height: auto; }
.banner-gap-b1 { padding-bottom: 50px; }
.has-mask .banner-gap-b1 { padding-bottom: 75px; }
.banner-gap-b2 { padding-bottom: 75px; }
.has-mask .banner-gap-b2 { padding-bottom: 125px; }
.banner-gap-b3 { padding-bottom: 75px; }
.has-mask .banner-gap-b3 { padding-bottom: 120px; }
.banner-gap-b4 { padding-bottom: 340px; }
.has-mask .banner-gap-b4 { padding-bottom: 360px; }
.banner-page .banner-wrap { padding: 40px 0; }
.is-transparent .banner-page .banner-wrap { padding-top: 20px; }
.banner-page .breadcumb { padding-top: 10px; }
.banner-page-md { min-height: 340px; }
.banner-page-lg { min-height: 460px; }
.banner-caption:not([class*=wide]) { max-width: 720px; margin-left: auto; margin-right: auto; }
.banner > .nk-block { position: relative; z-index: 2; }

.cpn { padding-top: 30px; padding-bottom: 30px; }
.cpn-head, .cpn-action, .cpn-text { margin-top: 20px; }
.cpn > *:first-child { margin-top: 0; }
.cpn-head .title { font-size: 1.75rem; line-height: 1.5; }
.cpn-text { color: #b9d2f4; font-weight: 300; font-size: 1.1rem; line-height: 1.8; margin-bottom: 25px; }
.cpn-links { padding-top: 20px; }
.cpn-links { margin: 0 -20px; }
.cpn-links li { display: inline-block; padding: 0 20px; font-weight: 500; }
.cpn-boxed { padding: 20px; background: rgba(11, 12, 16, 0.6); }
.cpn-boxed:not([class*='radius']) { border-radius: 6px; }

@media (min-width: 576px) { .banner-gap-b3 { padding-bottom: 120px; }
  .has-mask .banner-gap-b3 { padding-bottom: 160px; }
  .banner-screen { bottom: -40px; width: 100%; }
  .banner-screen-right { bottom: -70px; width: 360px; } }
@media (min-width: 768px) { .has-mask .banner { padding-bottom: 160px; }
  .banner-gap-b1 { padding-bottom: 75px; }
  .has-mask .banner-gap-b1 { padding-bottom: 125px; }
  .banner-gap-b2 { padding-bottom: 125px; }
  .has-mask .banner-gap-b2 { padding-bottom: 200px; }
  .banner-gap-b4 { padding-bottom: 340px; }
  .has-mask .banner-gap-b4 { padding-bottom: 360px; }
  .banner-page .banner-wrap { padding: 80px 0; }
  .is-transparent .banner-page .banner-wrap { padding-top: 60px; }
  .banner-page-md { min-height: 400px; }
  .banner-page-lg { min-height: 580px; }
  .cpn-text, .cpn-action { margin-top: 20px; }
  .cpn-text { margin-bottom: 15px; }
  .cpn-btns { padding-top: 10px; } }
@media (min-width: 992px) { .is-transparent .banner { padding-top: 90px; }
  .banner-gfx { margin: 0 -80px 0 -180px; }
  .banner-gfx-auto { margin: auto; }
  .has-mask .banner { padding-bottom: 180px; }
  .banner-gap-b3 { padding-bottom: 200px; }
  .has-mask .banner-gap-b3 { padding-bottom: 220px; }
  .banner-gap-b4 { padding-bottom: 60px; }
  .has-mask .banner-gap-b4 { padding-bottom: 80px; }
  .banner-screen-right { bottom: -45px; width: 100%; }
  .cpn { max-width: 90%; }
  .banner-page .cpn { max-width: 100%; }
  .cpn-head, .cpn-action, .cpn-text { margin-top: 30px; }
  .cpn-text { margin-bottom: 25px; max-width: 90%; }
  .cpn-btns, .cpn-links { padding-top: 20px; }
  .cpn-head .title { font-size: 2.125rem; }
  .cpn-head .title-sm { font-size: 1.85rem; }
  .cpn-boxed { padding: 35px; } }
@media (min-width: 1200px) { .banner-gfx { margin: 0 -380px 0 -180px; }
  .banner-gfx-auto { margin: auto; }
  .banner-gap-b3 { padding-bottom: 290px; }
  .has-mask .banner-gap-b3 { padding-bottom: 320px; }
  .banner-screen-right { bottom: -90px; } }
@media (min-width: 1600px) { .banner-caption:not([class*=wide]) { max-width: 1080px; } }
@media (max-width: 991px) and (orientation: landscape) { .banner-gfx { margin-left: auto; margin-right: auto; max-width: 390px; } }
@media (max-width: 767px) and (orientation: landscape) { .banner-gfx { max-width: 320px; } }
@media (min-width: 992px) and (max-width: 1200px) and (orientation: portrait) { .banner-fs { min-height: calc(65vh - 90px); }
  .is-transparent .banner-fs { min-height: auto; } }
/** 04.06 Head */
.section-head:not([class*=pdb]) { padding-bottom: 40px; }
.section-head p { color: #415076; }
.tc-light .section-head p { color: #8ca2e5; }
.section-head.tc-light p, .tc-light a.section-head:not(.btn) p { color: #8ca2e5; }
.section-head .title:not(:last-child) { margin-bottom: .75rem; }
.section-head .title-xs:not(:last-child) { margin-bottom: .5rem; }
.section-head .title:before { position: absolute; left: 50%; bottom: 0; content: attr(title); font-family: "Roboto", sans-serif; line-height: .9; text-transform: uppercase; white-space: nowrap; }
.section-head .title:not([class*='slide-']):not([class*='fade-']):before { transform: translateX(-50%); opacity: 0.06; }
.section-head-s2 .title:before { font-size: 48px; }

@media (min-width: 576px) { .section-head:not([class*=pdb]) { padding-bottom: 50px; }
  .section-head .title:not(:last-child) { margin-bottom: 1.25rem; }
  .section-head .title-xs:not(:last-child) { margin-bottom: .75rem; }
  .section-head-s2 .title:before { font-size: 64px; } }
@media (min-width: 992px) { .section-head:not([class*=pdb]) { padding-bottom: 60px; }
  .section-head-sm:not([class*=pdb]) { padding-bottom: 40px; }
  .section-head-s2 .title:before { font-size: 90px; }
  .section-head-s2 .title-xl:before { font-size: 124px; } }
/** 04.07 Features */
.feature { margin-bottom: 30px; display: block; }
.feature-group .feature { margin-bottom: 0; }
.feature-group { border-radius: 6px; }
.feature-icon { position: relative; width: 60px; margin-bottom: 15px; flex-shrink: 0; margin-right: 12px; }
.feature-icon-md { width: 40px; }
.feature-icon.dot:before { position: absolute; bottom: 0; right: 0; content: ''; height: 30px; width: 30px; border-radius: 50%; background: rgba(22, 163, 254, 0.5); z-index: 4; }
.tc-light .feature-icon .icon { color: #16a3fe; }
.feature-icon .icon { position: relative; font-size: 3.75rem; color: #1940b0; z-index: 5; margin-left: -5px; }
.feature-icon .icon.icon-circle { margin-left: 0; }
.feature-icon .icon-xs { font-size: 1rem; }
.feature-icon .icon-md { font-size: 2.75rem; }
.feature-icon .icon-lg { font-size: 6.875rem; }
.feature-inline { display: flex; }
.feature-middle { align-items: center; }
.feature-middle .feature-icon { margin-bottom: 0; margin-right: 15px; display: inline-flex; align-items: center; }
.feature-img { border-radius: 4px; }
.feature-text p:last-child { margin-bottom: 0; }
.feature-center { text-align: center; }
.feature-center .feature-icon { width: auto; margin-right: 0; }
.feature-3 { margin-bottom: 40px; }
.feature-3.card { padding: 30px 30px 40px; margin-bottom: 30px; }
.feature-4 .title { padding-top: 15px; }
.feature-6 { margin-bottom: 0; }

.boxed { padding: 15px 20px; }

@media (min-width: 576px) { .feature-icon { margin-bottom: 20px; margin-right: 20px; }
  .feature-2 { display: flex; align-items: flex-start; }
  .feature-3 { margin-bottom: 50px; padding: 0 30px; }
  .feature-3.card { margin-bottom: 30px; }
  .feature-6 { padding-bottom: 145px; }
  .feature-6:before, .feature-6:after { position: absolute; left: 50%; content: ''; transform: translateX(-50%); }
  .feature-6:before { bottom: 0; width: 2px; height: 95px; background: rgba(255, 255, 255, 0.8); border-radius: 2px; }
  .feature-6:after { bottom: 95px; width: 16px; height: 16px; border-radius: 50%; border: 3px solid #fff; background: #16a3fe; }
  .boxed { padding: 40px 50px; } }
@media (min-width: 992px) { .boxed-lg { padding: 55px 60px; } }
/** 04.08 Roadmap */
.roadmap { position: relative; z-index: 10; padding-bottom: 30px; }
.roadmap-s2 { text-align: center; }
.roadmap-s2:before, .roadmap-s2:after { position: absolute; content: ''; transition: width .3s; }
.roadmap-s2:before { width: 0; height: 4px; background: #16a3fe; left: 0; top: 55px; }
.roadmap-s2:after { left: 50%; top: 49px; transform: translateX(-50%); height: 16px; width: 16px; border-radius: 50%; border: 3px solid #5b77a3; background-color: #122272; }
.roadmap-s2.roadmap-finished:after, .roadmap-s2.roadmap-running:after { background-color: #16a3fe; border: 2px solid #fff; }
.roadmap-s2.roadmap-finished:before { width: 100%; }
.roadmap-s2.roadmap-running:before { width: 50%; }
.roadmap-s2.roadmap-running:after { box-shadow: 0 0 0 12px rgba(255, 255, 255, 0.1); }
.roadmap-slider { position: relative; }
.roadmap-text { max-width: 265px; margin-left: auto; margin-right: auto; }
.roadmap-wrap { position: relative; padding: 40px 0 10px; margin-bottom: 40px; max-width: 430px; margin-left: auto; margin-right: auto; }
.roadmap-wrap:before, .roadmap-wrap:after { position: absolute; content: ''; top: 0; bottom: 0; z-index: 7; }
.roadmap-wrap-ovl-right:after { right: 0; background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(18, 34, 114, 0.8) 25%, #122272 60%, #122272 100%); }
.roadmap-line { position: absolute; left: 19px; top: 0; background: #c1cef1; height: 100%; width: 2px; content: ''; z-index: 9; }
.roadmap-line:after, .roadmap-line:before { position: absolute; background: #c1cef1; height: 10px; width: 10px; border-radius: 50%; content: ''; left: -4px; }
.roadmap-line:before { top: 0; }
.roadmap-line:after { bottom: 0; }
.tc-light .roadmap-line, .tc-light a:not(.btn) .roadmap-line { background: rgba(255, 255, 255, 0.1); }
.tc-light .roadmap-line:after, .tc-light .roadmap-line:before { background: #16a3fe; }
.bg-theme-dark .roadmap-line { background: #1940b0; }
.bg-theme-dark .roadmap-line:after, .bg-theme-dark .roadmap-line:before { background: #16a3fe; }
.bg-theme .roadmap-line { background: #1940b0; }
.bg-theme .roadmap-line:after, .bg-theme .roadmap-line:before { background: #16a3fe; }
.roadmap-line-s2 { position: absolute; width: 100%; height: 4px; background: #5b77a3; content: ''; top: 95px; border-radius: 2px; left: 0; }
.roadmap-year { border-radius: 50%; color: #fff; background: #122272; height: 50px; width: 50px; text-align: center; line-height: 50px; font-weight: 700; font-size: 12px; position: relative; left: -5px; }
.tc-light .roadmap-year, .tc-light a:not(.btn) .roadmap-year { background: #16a3fe; }
.bg-theme .roadmap-year, .bg-theme-dark .roadmap-year { background: #16a3fe; }
.roadmap-step { border: 2px solid #dbe4f7; border-radius: 5px; position: relative; margin-left: 50px; padding: 20px 20px 15px; position: relative; max-width: 380px; }
.bg-light .roadmap-step { background: #fff; }
.roadmap-step:before, .roadmap-step:after { position: absolute; content: ''; }
.roadmap-step:before { height: 18px; width: 18px; border-radius: 50%; border: 5px solid #fff; background: #122272; left: -41px; top: 34px; }
.roadmap-step:after { height: 2px; width: 22px; background: #dbe4f7; left: -22px; top: 42px; }
.roadmap-step p:last-child { margin-bottom: 0; }
.roadmap-step-list li:before { background: #415076; }
.tc-light .roadmap-step-list li:before { background: #23c99d; }
.tc-light .roadmap-step, .tc-light a:not(.btn) .roadmap-step { background: rgba(255, 255, 255, 0.05); border-color: rgba(255, 255, 255, 0.1); }
.tc-light .roadmap-step:before { background: #16a3fe; }
.tc-light .roadmap-step:after { background: rgba(255, 255, 255, 0.1); }
.bg-theme .roadmap-step, .bg-theme-dark .roadmap-step { background: #132582; border-color: #1940b0; }
.bg-theme .roadmap-step:before, .bg-theme-dark .roadmap-step:before { background: #16a3fe; border-color: #122272; }
.bg-theme .roadmap-step:after, .bg-theme-dark .roadmap-step:after { background: #1940b0; }
.bg-theme-dark .roadmap-step:before { border-color: #0c195a; }
.bg-theme-alt .roadmap-step:before { border-color: #1940b0; }
.bg-light .roadmap-step:before { border-color: #f6fafd; }
.has-bg-image .roadmap-step:before { border-color: transparent; }
.roadmap-head { margin-bottom: 10px; padding-right: 35px; position: relative; }
.roadmap-finished .roadmap-head:after { position: absolute; right: 0; top: 50%; transform: translateY(-50%); width: 32px; height: 32px; line-height: 32px; border-radius: 50%; text-align: center; color: #fff; background: #23c99d; font-family: "Font Awesome 5 Free"; font-weight: 700; font-size: .88rem; content: '\f00c'; }
.roadmap-time { display: block; color: #122272; font-size: 13px; text-transform: uppercase; }
.tc-light .roadmap-time, .tc-light a:not(.btn) .roadmap-time { color: #16a3fe; }
.roadmap-title { color: #8ca2e5; font-weight: 700; font-size: 16px; }
.tc-light .roadmap-title, .tc-light a:not(.btn) .roadmap-title { color: #fff; }

@media (min-width: 576px) { .roadmap-wrap-ovl-right { max-width: none; margin-right: -200px; }
  .roadmap-wrap-ovl-right:after { width: 280px; }
  .roadmap-wrap-ovl-right .owl-nav { margin-right: 190px; } }
@media (min-width: 992px) { .roadmap { display: flex; }
  .roadmap-s2 { display: block; }
  .roadmap-wrap { padding: 40px 0 10px; margin-bottom: 60px; max-width: 100%; }
  .roadmap-wrap-ovl-left, .roadmap-wrap-ovl-right { max-width: none; }
  .roadmap-line { left: 50%; margin-left: -1px; }
  .roadmap-left { justify-content: flex-start; margin-top: -80px; }
  .roadmap-left .roadmap-step { margin-left: 0; margin-right: 80px; }
  .roadmap-left .roadmap-step:before { left: auto; right: -96px; }
  .roadmap-left .roadmap-step:after { left: auto; right: -78px; }
  .roadmap-right { justify-content: flex-end; }
  .roadmap-right .roadmap-step { margin-left: 80px; }
  .roadmap-year { height: 60px; width: 60px; line-height: 60px; font-size: 16px; position: static; margin-left: auto; margin-right: auto; }
  .roadmap-step { width: 100%; padding: 40px 30px 35px; }
  .roadmap-step:before, .roadmap-step:after { position: absolute; content: ''; }
  .roadmap-step:before { left: -96px; }
  .roadmap-step:after { width: 78px; left: -78px; }
  .roadmap-step-list li:before { top: 12px; }
  .roadmap-head { margin-bottom: 15px; }
  .roadmap-time { font-size: 14px; }
  .roadmap-title { font-size: 18px; } }
@media (min-width: 1200px) { .roadmap-left .roadmap-step:before { right: -91px; }
  .roadmap-left .roadmap-step:after { right: -73px; }
  .roadmap-step:before { left: -91px; }
  .roadmap-step:after { width: 73px; left: -73px; } }
/** 04.091 Token */
.token-info { border: 1px solid rgba(22, 163, 254, 0.2); border-radius: 8px; padding: 18px 20px 0; margin-bottom: 30px; }
.token-info-item { position: relative; padding-left: 25px; }
.token-info-item:before { position: absolute; left: 0; top: 0; bottom: 0; width: 5px; content: ''; border-radius: 2px; background: #a963ff; }
.token-info-item.color-2:before { background: #f42f54; }
.token-info-item.color-3:before { background: #f5a946; }
.token-info-item.color-4:before { background: #63a5ff; }
.token-info-item.color-5:before { background: #ff8d87; }
.token-info-title { font-size: 0.7rem; text-transform: uppercase; font-weight: 700; letter-spacing: 0.04em; color: #8ca2e5; margin-bottom: 4px; }
.token-info-des { font-size: 1.1rem; }
.token-status { border-radius: 8px 8px 0 0; border: 1px solid rgba(22, 163, 254, 0.2); padding: 18px 20px 10px; text-align: center; }
.token-countdown { padding: 16px 0 20px; }
.token-min { font-size: 13px; color: #8ca2e5; display: block; padding: 12px 0; }
.token-alocate-item { padding: 0 20px 15px; margin-bottom: 30px; margin-left: auto; margin-right: auto; background: #fff; height: calc(100% - 30px); border-radius: 4px; }
.token-alocate-item:not([class*='shadow']) { box-shadow: 0px 5px 45px 0px rgba(18, 24, 58, 0.08); }
.token-alocate-graph { padding: 20px 0 15px; position: relative; }
.token-alocate-graph span { color: #415076; font-size: 2em; font-weight: 400; position: absolute; line-height: 1.3; text-align: center; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 4; }
.token-alocate-graph canvas { position: relative; z-index: 5; }
.token-alocate-list li { color: #415076; display: flex; align-items: center; font-weight: 400; padding: 13px 0; }
.token-alocate-list li .token-alocate-percent { font-weight: 600; color: #122272; }

@media (min-width: 576px) { .token-info { padding: 24px 40px 0; }
  .token-info .text-block-title { text-align: center; }
  .token-info-list li { display: flex; }
  .token-info-list li > span { width: 50%; }
  .token-info-title { font-size: 0.88rem; letter-spacing: 0.2em; }
  .token-info-des { font-size: 1.375rem; }
  .token-status { padding: 24px 28px 20px; }
  .token-alocate-item { padding: 30px 65px 60px; }
  .token-alocate-graph { padding: 30px 40px 30px; } }
@media (min-width: 768px) { .token-alocate-item { padding: 20px 30px 40px; }
  .token-alocate-graph { padding: 30px 0 30px; } }
@media (min-width: 991px) { .token-alocate-item { padding: 30px 65px 60px; }
  .token-alocate-graph { padding: 30px 0 30px; } }
/** 04.10 Team */
.team { margin-bottom: 30px; max-width: 255px; margin-left: auto; margin-right: auto; text-align: center; }
.team:hover .team-show { opacity: 1; }
.team-s2 { max-width: 240px; text-align: center; }
.team-s2:hover .team-show { opacity: .25; }
.team-sm-s2 { text-align: left; max-width: 145px; }
.team-photo { display: block; position: relative; margin-left: auto; margin-right: auto; transition: all .4s; }
.team-photo img { border-radius: 6px; width: 100%; }
.team-photo-sm { max-width: 180px; }
.team-photo-s2 { max-width: 90%; }
.team-photo-s2:before { position: absolute; left: 0; top: 0; content: ''; height: 100%; width: 100%; background: rgba(22, 163, 254, 0.4); border-radius: 6px; z-index: 1; }
.team-sm-s2 .team-photo { margin-left: 0; max-width: 90%; }
.team-sm-s2:hover .team-photo { transform: translateY(-8px); }
.team-show { position: absolute; left: 0; top: 0; content: ''; height: 100%; width: 100%; background: rgba(22, 163, 254, 0.4); border-radius: 6px; transition: all .4s; z-index: 1; opacity: 0; }
.team-s2 .team-show { opacity: 0; }
.team-social { margin: 0 -5px; }
.team-photo .team-social { position: absolute; bottom: -17px; right: 17px; z-index: 2; text-align: right; }
.team-social li { padding: 0 4px; display: inline-block; }
.team-social li a { font-size: 13px; text-align: center; display: inline-block; line-height: 34px; height: 34px; width: 34px; border-radius: 50%; color: #fff; background: #23c99d; box-shadow: 0px 2px 15px 0px rgba(35, 201, 157, 0.5); }
.team-social li a:hover { background: #16a3fe; box-shadow: 0px 2px 15px 0px rgba(22, 163, 254, 0.5); }
.team-social-s2 { margin: 0 -10px; }
.team-social-s2 li { padding: 0 10px; }
.team-social-s2 li a { width: auto; background-color: transparent; box-shadow: none; }
.team-social-s2 li a:hover { background-color: transparent; box-shadow: none; }
.team-s2 .team-social-s2 { padding-top: 15px; }
.team-name { color: #415076; padding-top: 28px; font-weight: 600; }
.team-name.title { margin-bottom: .5rem !important; }
.team-s2 .team-name.title-sm { font-size: .95em !important; }
.team-s2 .team-name { padding-top: 18px; }
.team-position { font-size: 12px; font-weight: 400; display: inline-block; }
.tc-light .team-position, .tc-light a:not(.btn) .team-position { color: #b9d2f4; }
.team-featured-photo { position: relative; border-radius: 5px; overflow: hidden; }
.team-featured-info { position: absolute; left: 0; bottom: 0; font-size: 18px; padding: 15px 20px; }
.team-featured-info span { font-size: 16px; font-weight: 300; display: block; padding-top: 5px; }
.team-featured-cont p:last-child { margin-bottom: 0; }
.team-featured-sub { text-transform: uppercase; color: #8ca2e5; font-size: 14px; letter-spacing: 0.15em; }
.team-popup { position: relative; max-width: 100%; margin: 10px; padding: 15px; background: #fff; border-radius: 6px; }
.team-popup-info p:last-child { margin-bottom: 0; }
.team-list { margin-bottom: -30px; }

@media (min-width: 576px) { .team { margin-bottom: 50px; }
  .team-s2 { margin-bottom: 30px; text-align: left; }
  .team-s2 .team-name { padding-top: 25px; }
  .team-featured-info { padding: 25px 30px; }
  .team-popup { padding: 40px; }
  .team-list { margin-bottom: -50px; } }
@media (min-width: 992px) { .team-featured-cont { padding-left: 20px; }
  .team-featured-title { padding: 8px 0 15px; }
  .team-name { padding-top: 34px; margin-bottom: 8px !important; }
  .team-s2 .team-name { padding-top: 20px; }
  .team-position { font-size: 0.88rem; }
  .team-position-sm { font-size: 13px; }
  .team-social { bottom: -20px; right: 20px; margin: 0 -5px; }
  .team-social li { padding: 0 4px; }
  .team-social li a { font-size: 16px; line-height: 40px; height: 40px; width: 40px; }
  .team-social-s2 { margin: 0 -8px; }
  .team-social-s2 li { padding: 0 8px; }
  .team-social-s2 li a { font-size: 14px; height: auto; width: auto; }
  .team-popup { margin: 10px auto; width: 950px; } }
@media (min-width: 1600px) { .team-photo-s2 { max-width: 100%; } }
/** 04.11 Partners */
.partner-list { display: flex; flex-wrap: wrap; justify-content: center; margin-bottom: -15px; }
.partner-list-lg { margin-bottom: -20px; }
.partner-logo { padding: 15px 10px; }
[class*="col-"] > .partner-logo { padding: 0; }
.partner-logo img { max-height: 30px; max-width: 100%; width: auto !important; margin-left: auto; margin-right: 0; }
.has-carousel .partner-logo img { margin-right: auto; }
.partner-list-lg .partner-logo { padding: 20px 25px; }
.partner-list-lg .partner-logo img { max-height: 50px; }

@media (min-width: 992px) { .partner-list-lg { margin-bottom: -20px; }
  .partner-list-lg .partner-logo { padding: 20px 25px; }
  .partner-list-lg .partner-logo img { max-height: 50px; }
  [class*="col-"] > .partner-logo { padding: 15px 0; } }
/** 04.12 Contact */
.contact-wrap { padding: 20px; }
.contact-list { margin-top: -7.5px; margin-bottom: -7.5px; }
.contact-list li { display: flex; padding-top: 7.5px; padding-bottom: 7.5px; font-size: 0.94rem; }
.contact-icon { height: 38px; width: 38px; font-size: 16px; line-height: 38px; text-align: center; color: #122272; background: #fff; border-radius: 50%; margin-right: 15px; flex-shrink: 0; }
.bg-secondary .contact-icon { color: #23c99d; }
.contact-text { padding-top: 7px; }
.contact-social { margin-top: auto; padding-top: 20px; }
.contact-social h6 { margin-bottom: 1rem; }

.subscribe-wrap { padding: 20px; }

@media (min-width: 576px) { .contact-wrap { padding: 50px; }
  .subscribe-wrap { padding: 65px; } }
@media (min-width: 992px) and (max-width: 1199px) { .contact-wrap, .subscribe-wrap { padding: 35px; } }
.social-links { display: flex; margin: -3px; }
.social-links li { padding: 3px; }
.social-links li a { display: inline-block; border-radius: 50%; border: 1px solid #fff; height: 36px; width: 36px; line-height: 36px; text-align: center; color: #415076; }
.social-links li a:hover { color: #16a3fe; background: #fff; }
.tc-light .social-links li a { color: #fff; }
.tc-light .social-links li a:hover { color: #16a3fe; }
.bg-secondary .social-links li a:hover { color: #23c99d !important; }

@media (min-width: 992px) { .section-contact .ovm-h-60 { height: 50%; } }
/** 04.13 WGS */
.wgs { margin-bottom: 30px; }
.wgs-title { text-transform: uppercase; color: #1940b0; font-size: 1rem; font-weight: 500; letter-spacing: 0.01em; }
.tc-light .wgs-title, .tc-light a:not(.btn) .wgs-title { color: #23c99d; }
.wgs-logo { padding-bottom: 22px; display: inline-block; }
.wgs-body p { font-size: 0.93em; line-height: 1.71; margin-bottom: 0.75rem; }
.wgs-body p:last-child { margin-bottom: 0; }
.wgs-links li { position: relative; }
.wgs-links li a { font-size: 0.87em; line-height: 20px; padding: 5px 0; display: block; color: #12183a; }
.wgs-links li a:hover { color: #16a3fe; }
.wgs-links-category li { padding-left: 20px; }
.wgs-links-category li a { padding: 10px 0; }
.wgs-links-category li:before { position: absolute; left: 0; top: 50%; margin-top: -1px; height: 2px; width: 8px; content: ''; background: #16a3fe; }
.wgs-links-tags { padding: 12px 0; margin: -7px -5px; }
.wgs-links-tags li { display: inline-block; padding: 7px 5px; }
.wgs-links-tags li a { padding: 7px 16px; border: 1px solid #dbe4f7; border-radius: 3px; }
.wgs-links-tags li a:hover { color: #fff; border-color: #16a3fe; background: #16a3fe; }
.wgs-archive .wgs-body { padding: 12px 0; }
.wgs-cta .wgs-body { padding: 30px 30px 35px; }

.copyright-text, .copyright-text a { color: #415076; }
.copyright-text a:hover { color: #16a3fe; }
.copyright-text-s2 { text-transform: uppercase; font-size: 11px; letter-spacing: 0.1em; }

.footer-nav { margin: 0 -15px; }
.footer-nav li { display: inline-block; padding: 0 15px; }
.footer-nav li a { color: #415076; font-size: 12px; text-transform: uppercase; letter-spacing: 0.1em; }
.footer-nav li a:hover { color: #16a3fe !important; }

@media (min-width: 576px) { .copyright-text-s2 { font-size: 12px; } }
/** 04.14 Others */
.rank-list { display: flex; flex-wrap: wrap; align-items: center; justify-content: center; }
.rank-item { background-image: url(../images/hexagon.png); height: 88px; width: 94px; padding: 16px 20px 0; }
.rank-item img { max-height: 16px; }
.rank-count { font-size: 1rem; color: #1940b0; font-weight: 700; padding-top: 4px; line-height: 1.3; }
.rank-count span { font-size: .7rem; color: #8ca2e5; }

@media (min-width: 400px) { .rank-item { height: 104px; width: 108px; padding-top: 24px; } }
@media (min-width: 768px) { .rank-item { height: 136px; width: 146px; padding: 32px 30px 0; }
  .rank-item img { max-height: 24px; }
  .rank-count { padding-top: 10px; font-size: 1.4rem; }
  .rank-count span { font-size: .9rem; } }
@media (min-width: 992px) { .rank-item { height: 160px; width: 164px; padding-top: 38px; } }
.bonus { border-radius: 0 0 8px 8px; border: 1px solid rgba(22, 163, 254, 0.2); border-top: none; display: flex; }
.bonus-info { position: relative; width: 50%; padding: 12px 16px 16px; }
.bonus-info:not(:last-child) { border-right: 1px solid rgba(22, 163, 254, 0.2); }
.bonus-percent { font-size: 20px; }
.bonus-percent small { font-size: 14px; }
.bonus-date { font-size: 12px; color: #8ca2e5; }
.bonus-badge { position: absolute; top: 5px; right: 5px; text-transform: uppercase; border-radius: 11px; line-height: 15px; font-size: 8px; font-weight: 500; padding: 0 8px; background: #23c99d; }

@media (min-width: 420px) { .bonus-info { padding: 12px 30px 16px; }
  .bonus-percent { font-size: 28px; }
  .bonus-percent small { font-size: 14px; }
  .bonus-date { font-size: 12px; color: #8ca2e5; }
  .bonus-badge { top: 50%; right: 0; transform: translateY(-50%) rotate(-90deg); line-height: 22px; font-size: 11px; padding: 0 10px; } }
.chart-data li { display: flex; font-size: 1rem; line-height: 1.4; font-weight: 400; padding: 7px 0; }
.chart-data li span { display: block; }
.chart-data-s2 { display: flex; flex-wrap: wrap; margin: -10px; }
.chart-data-s2 li { padding: 10px; }
.chart-data-item { position: relative; padding: 15px 25px; }
.chart-data-item:before, .chart-data-item:after { position: absolute; content: ''; top: 0; height: 100%; width: 45px; border: 2px solid #5b77a3; transition: all .4s; }
.active .chart-data-item:before, .active .chart-data-item:after { border-color: #f42f54; }
.chart-data-item:before { left: 0; border-radius: 6px 0 0 6px; border-right: none; }
.chart-data-item:after { right: 0; border-radius: 0 6px 6px 0; border-left: none; }
.chart-label { font-size: 14px; font-weight: 700; color: #fff; transition: all .4s; }
.active .chart-label { color: #f42f54; }
.chart-info { display: block; }
.chart-percent { font-size: 22px; font-weight: 400; color: #fff; }
.chart-sublabel { font-size: 13px; color: #8ca2e5; margin-left: 8px; }
.chart-p { margin-left: auto; font-size: 1.125rem; font-weight: 500; color: #1940b0; }
.chart-c { position: relative; top: 3px; height: 14px; width: 14px; border-radius: 3px; margin-right: 12px; flex-shrink: 0; display: block; }
.chart-canvas { min-height: 300px; max-width: 100%; }

@media (min-width: 576px) { .chart-label, .chart-percent { font-size: 18px; }
  .chart-c { top: 6px; }
  .chart-canvas { min-height: 360px; } }
.ath-container { width: 440px; max-width: 100%; margin: 30px auto; }
.ath-header { padding: 0 0 30px; }
.ath-heading { text-align: center; font-weight: 300; }
.ath-heading small { display: block; padding-bottom: 10px; }
.ath-body, .quiz-body { background: #fff; border-radius: 6px; padding: 18px 25px 25px; }
.ath-note { padding: 25px 0 0; }

@media (min-width: 576px) { .ath-body, .quiz-body   { padding: 32px 40px 40px; } }
.sap-text { position: relative; text-align: center; padding: 20px 0; }
.sap-text:before { position: absolute; top: 50%; left: 0; width: 100%; height: 1px; content: ''; background: #dbe4f7; z-index: 3; }
.sap-text span { position: relative; background: #fff; display: inline-block; padding: 0 15px; z-index: 5; font-size: .9rem; text-transform: uppercase; }

.blog { background: #fff; border-radius: 6px; box-shadow: 0px 5px 45px 0px rgba(18, 24, 58, 0.08); margin-bottom: 30px; }
.blog-photo { padding: 30px 30px 0; }
.blog-photo > img { border-radius: 6px 6px 0 0; }
.blog-text { padding: 25px 30px 30px; }
.blog-text p { font-size: 0.88rem; }
.blog-s2 .blog-title { margin-bottom: 8px; }
.blog-content-inline { padding: 18px 30px 14px; display: flex; align-items: center; flex-wrap: wrap; justify-content: space-between; }
.blog-content .blog-text { padding: 9px 0; }
.blog-meta { margin-bottom: .25rem; margin: 0 -7px 4px; }
.blog-meta li { position: relative; display: inline-block; font-size: .88rem; padding: 0 7px; }
.blog-meta li:not(:last-child):after { content: '|'; position: absolute; right: -3px; top: -1px; color: #8ca2e5; }
.blog-meta li a { color: #2a3888; }
.blog-meta li a:hover { color: #16a3fe; }
.blog-tags { padding: 12px 0; margin: -7px -5px; }
.blog-tags li { display: inline-block; padding: 7px 5px; }
.blog-tags li a { padding: 7px 16px; border: 1px solid #dbe4f7; border-radius: 3px; color: #12183a; font-size: .88rem; }
.blog-tags li a:hover { color: #fff; border-color: #16a3fe; background: #16a3fe; }
.blog-featured { margin-bottom: 30px; }
.blog-comment { padding-top: 25px; }
.blog-action { padding: 9px 0; }

.comment-wrap { background: #f6fafd; border-radius: 6px; overflow: hidden; margin-bottom: 30px; }
.comment-item { padding: 20px; }
.comment-item:not(:last-child) { border-bottom: 1px solid #fff; }
.comment-item + .comment-item { padding-left: 40px; }
.comment-item + .comment-item + .comment-item { padding-left: 60px; }
.comment-photo { width: 50px; flex-shrink: 0; margin-bottom: 10px; margin-right: 20px; }
.comment-content { padding-top: 6px; }
.comment-info .title { margin-bottom: 0; }
.comment-time { font-size: .75rem; }
.comment-text { margin-top: 8px; }
.comment-text p { font-size: .88rem; }

@media (min-width: 576px) { .comment-wrap { margin-bottom: 30px; }
  .comment-item { display: flex; padding: 30px; }
  .comment-item + .comment-item { padding-left: 60px; }
  .comment-item + .comment-item + .comment-item { padding-left: 90px; }
  .comment-photo { margin-bottom: 0; } }
.pagination { margin-left: auto; margin-right: auto; display: inline-flex; justify-content: center; box-shadow: 0px 5px 45px 0px rgba(18, 24, 58, 0.08); }
.pagination li a { display: inline-block; height: 50px; min-width: 56px; text-align: center; line-height: 20px; padding: 15px 5px; background: #fff; color: #8ca2e5; }
.pagination li a .icon { line-height: 20px; padding: 0 5px; }
.pagination li a.active, .pagination li a:hover { background: #16a3fe; color: #fff; }
.pagination li a.active .icon, .pagination li a:hover .icon { color: #fff; }
.pagination li:first-child a { border-radius: 6px 0 0 6px; }
.pagination li:last-child a { border-radius: 0 6px 6px 0; }

.download-option { text-align: center; position: relative; padding: 10px; margin: 15px 0; }
.download-option .icon { display: block; padding-bottom: 35px; font-size: 44px; color: #415076; }

@media (min-width: 992px) { .download-option:not(:last-child):after { position: absolute; content: ''; right: 0; top: 50%; transform: translateY(-50%); height: 100%; width: 1px; background: rgba(162, 179, 205, 0.3); } }
.doc { text-align: center; position: relative; }
.doc:hover { z-index: 9; }
.doc-photo { max-width: 125px; margin-left: auto; margin-right: auto; transition: all .2s linear; }
.doc-photo img { border-radius: 4px; }
.doc:hover .doc-photo { transform: translateY(-10px); }
.doc-shape { padding-top: 60px; position: relative; }
.doc-shape:before { position: absolute; top: 0; left: 50%; margin-left: -5px; transform: translateX(-50%); transform-origin: 50% 50%; content: ''; background: url(../images/polygon-a.png) 50% 0/contain no-repeat; width: 215px; height: 205px; z-index: -1; transition: all .2s linear; }
.doc-shape-a:before { transform: translateX(-50%) rotate(0deg); }
.doc:hover .doc-shape-a:before { transform: translateX(-50%) translateY(10px) rotate(0deg) scale(1.1); }
.doc-shape-b:before { transform: translateX(-50%) rotate(90deg); }
.doc:hover .doc-shape-b:before { transform: translateX(-50%) translateY(10px) rotate(90deg) scale(1.1); }
.doc-shape-c:before { transform: translateX(-50%) rotate(-90deg); }
.doc:hover .doc-shape-c:before { transform: translateX(-50%) translateY(10px) rotate(-90deg) scale(1.1); }
.doc-shape-d:before { transform: translateX(-50%) rotate(135deg); }
.doc:hover .doc-shape-d:before { transform: translateX(-50%) translateY(10px) rotate(135deg) scale(1.1); }
.doc-title { margin-top: 15px; padding-bottom: 8px; }
.doc-title small { display: block; color: #8ca2e5; }
.doc-download { font-size: 18px; line-height: 19px; padding: 8px 15px; min-width: 60px; border-radius: 4px; color: #fff; background: #16a3fe; display: inline-block; }
.doc-lang { text-transform: uppercase; font-size: 11px; margin-top: 5px; }

.tc-light a.doc-download { color: #fff !important; }
.tc-light a.doc-download:hover { color: #16a3fe !important; background: #fff; }

@media (min-width: 1200px) { .doc-shape:before { width: 255px; height: 245px; }
  .doc-photo { max-width: 175px; } }
.particles-container { position: absolute !important; top: 0; left: 0; right: 0; bottom: 0; z-index: 0 !important; }
.particles-container.is-fixed { position: fixed !important; z-index: -1 !important; }

/** 05. Layouts */
/** 05.01 Header */
.header-main { padding: 12px 0; transition: all .4s;  }
.is-transparent .header-main, .has-fixed .header-main { width: 100%; z-index: 49; }
.is-transparent .header-main { position: absolute !important; }
.has-fixed .header-main { position: fixed !important; box-shadow: 0 0 20px 0 rgba(18, 24, 58, 0.08); }
.has-fixed:not(.is-dark) .header-main { background: #fff; }
.has-fixed.is-dark .header-main { background-color: #08103b; }
.has-fixed.is-shrink .header-main { padding: 8px 0; }
.header-container:not(.container) { width: 100%; padding: 0 15px; }
.header-wrap { position: relative; width: 100%; display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; }
.header-logo { margin: 6px 0; flex-shrink: 0; }
.header-navbar { position: fixed; left: -280px; top: 0; width: 260px; height: 100vh; transition: all 0.4s; }
.header-navbar::before, .header-navbar-overlay { position: absolute; height: 100%; width: 100%; content: ''; top: 0; }
.header-navbar::before { background: #fff; left: 50%; transform: translateX(-50%); transition: all .4s; }
.is-dark .header-navbar::before { background: #1c48c6; }
.header-navbar-overlay { background: rgba(11, 12, 16, 0.7); left: 100%; width: 0; z-index: -1; opacity: 0; visibility: hidden; transition: opacity .3s; transition-delay: 0.2s; }
.header-navbar.menu-shown { left: 0 !important; }
.header-navbar.menu-shown .header-navbar-overlay { width: 100vw; opacity: 1; visibility: visible; }
.header-navbar-classic { position: absolute; top: 99%; right: 0; left: 0; width: 100%; height: auto; background: transparent; margin-top: 12px; z-index: auto; flex-grow: 1; transition: all 0.4s; padding: 0 20px; }
.has-fixed .header-navbar-classic { margin-top: -6px; padding: 0; }
.header-navbar-classic:before { border-radius: 4px; }
.has-fixed .header-navbar-classic:before { width: 100vw; border-radius: 0; }
.header-menu { justify-content: flex-end; padding: 30px 25px; }
.header-menu ul li a{
  color: #000;
}
.header-navbar-classic .header-menu { padding: 0; }
.header-banner { min-height: 72px; }

@media (min-width: 375px) { .header-navbar:not(.header-navbar-classic) { left: -310px; width: 290px; } }
@media (min-width: 576px) { .header-navbar:not(.header-navbar-classic) { left: -360px; width: 340px; } }
.logo-dark, .is-transparent:not(.has-fixed) .logo-light, .is-dark .logo-light { display: block; }

.logo-light, .is-transparent:not(.has-fixed) .logo-dark, .is-dark .logo-dark { display: none; }

.logo img { height: 36px; transition: all .4s; }
.logo-md img { height: 44px; }
.logo-lg img { height: 52px; }

@media (min-width: 992px) { .header-navbar:not(.header-navbar-classic) { height: auto; width: auto; left: 0; }
  .header-main { padding: 20px 0; }
  .header-nav-toggle { display: none; }
  .header-container:not(.container) { padding: 0 25px; }
  .header-banner { min-height: 98px; }
  .logo img { height: 40px; }
  .logo-md img { height: 50px; }
  .logo-lg img { height: 60px; } }
.header-menu { max-height: 100vh; overflow: hidden; overflow-y: auto; }
.header-navbar-classic .header-menu { display: none; max-height: 65vh; }
.header-navbar-classic .header-menu.menu-shown { display: block; }
.header-nav-toggle { height: 44px; }

/** 05.02 Menu */
.menu { padding: 12px 0; }
.menu-item a{
	color: #000;
}
.menu-btns { margin-bottom: 26px; margin-left: -10px; margin-right: -10px; margin-top: -10px; display: flex; }
.menu-btns > li { padding: 3px; padding: 3px; }
.menu-item { position: relative; border-bottom: 1px solid rgba(219, 228, 247, 0.75); color: #000 }
.is-dark .menu-item { border-color: rgba(219, 228, 247, 0.1); }
.menu-item:last-child { border-bottom: 0; }
.menu-item > ul, .menu-item .menu-sub { display: none; }
.menu-item a { font-family: "Roboto", sans-serif; font-size: 15px; line-height: 18px; color: #415076; padding: 12px 0; display: block; position: relative; }
.menu-item a .badge { margin-left: 5px; position: relative; top: -1px; }
.menu-item a .active, .menu-item a:hover { color: #16a3fe; }
.menu-sub { padding-left: 12px; }
.menu-sub .menu-item:first-child { border-top: 1px solid rgba(219, 228, 247, 0.75); }
.is-dark .menu-sub .menu-item:first-child { border-color: rgba(219, 228, 247, 0.1); }
.menu-sub .menu-sub { margin-top: -5px; margin-bottom: 10px; }
.menu-sub .menu-sub .menu-item { border-bottom: none; }
.menu-sub .menu-sub .menu-item:first-child { border-top: none; }
.menu-sub .menu-sub .menu-item a { padding-top: 6px; padding-bottom: 6px; }
.menu-sub a { font-family: "Roboto", sans-serif; font-size: 14px; color: #415076; }
.menu-mega a { font-family: "Roboto", sans-serif; font-size: 14px; color: #415076; }
.is-dark .menu-item a { color: #fff; }
.menu-item > a.active { color: #16a3fe; }

.has-sub > a { position: relative; }
.has-sub > a:after { position: absolute; right: 0; top: 50%; transform: translateY(-50%); content: '\e64b'; font-family: 'themify'; font-size: 8px; font-weight: 700; transition: transform .4s; }
.has-sub.open-nav > a:after { transform: translateY(-50%) rotate(-180deg); }

.navbar-toggle { width: 44px; height: 44px; text-align: center; display: inline-block; border-radius: 50%; padding: 5px; display: inline-flex; align-items: center; justify-content: center; transition: all 0.3s; }
.navbar-toggle.active { color: #16a3fe; position: relative; z-index: 49; }
.navbar-toggle:hover, .navbar-toggle.active { background: rgba(246, 250, 253, 0.2); }
.is-transparent:not(.has-fixed) .navbar-toggle { color: #fff; }
.is-transparent:not(.has-fixed) .navbar-toggle:hover, .is-transparent:not(.has-fixed) .navbar-toggle.active { background: rgba(246, 250, 253, 0.2); }

.toggle-line { position: relative; width: 32px; height: 32px; display: block; color: #415076; }
.toggle-line:hover { color: #415076; }
.is-transparent:not(.has-fixed) .toggle-line, .is-dark.has-fixed .toggle-line { color: #fff; }
.toggle-line::after, .toggle-line::before, .toggle-line > span { content: ""; position: absolute; background: currentColor; transition: all 0.2s; width: 24px; height: 2px; left: 4px; border-radius: 3px; }
.toggle-line::before { top: 8px; }
.toggle-line::after { bottom: 8px; }
.toggle-line > span { top: 50%; margin-top: -1px; width: 20px; }
.active .toggle-line { color: #fff; }
.active .toggle-line::before, .active .toggle-line::after { width: 28px; left: 2px; }
.active .toggle-line::before { transform: rotate(45deg); top: 15px; }
.active .toggle-line::after { transform: rotate(-45deg); bottom: 15px; }
.active .toggle-line > span { opacity: 0; visibility: hidden; }

@media (min-width: 992px) { .header-menu { display: flex !important; width: auto; align-items: center; position: static; background: transparent; padding: 0; margin: 0; border-radius: 0; overflow: visible; }
  .header-navbar { padding: 0 0 0 15px; margin-top: 0; position: relative; }
  .header-navbar:before { display: none; }
  .has-fixed .header-navbar { margin-top: 0; }
  .menu { display: flex; align-items: center; padding: 0; }
  .menu-item { border-bottom: none; }
  .menu-item > a { font-weight: 400; padding: 20px 10px; }
  .menu-drop .menu-item > a { font-weight: 300; }
  .menu-item:hover { z-index: 9999; }
  .menu-sub { position: absolute; min-width: 180px; max-width: 180px; background: #fff; padding: 15px 0; top: 100%; left: 0; display: block !important; z-index: 99; opacity: 0; visibility: hidden; transition: all .4s; border-radius: 4px; transform: translateX(0) translateY(-8px); box-shadow: 0px 3px 12px 0px rgba(18, 24, 58, 0.08); }
  .menu-sub a { width: 100%; display: block; padding: 10px 10px; }
  .menu-sub .menu-item:first-child { border-top: none; }
  .menu-sub .menu-item:hover > a { color: #16a3fe; }
  .menu-item:hover > .menu-sub { opacity: 1; visibility: visible; transform: translateX(0) translateY(0); }
  .menu-sub .menu-sub { left: calc(100% - 25px); top: -15px; margin: 0; }
  .menu-sub .menu-sub .menu-item a { padding-top: 12px; padding-bottom: 12px; }
  .is-dark .menu-sub { background-color: #1d4bcf; }
  .menu-mega { position: absolute; left: 50%; background: #fff; padding: 15px 10px; top: 100%; display: block !important; z-index: 99; opacity: 0; visibility: hidden; transition: all .4s; border-radius: 4px; box-shadow: 0px 3px 12px 0px rgba(18, 24, 58, 0.08); transform: translateX(-50%) translateY(-8px); }
  .menu-item:hover > .menu-mega { opacity: 1; visibility: visible; transform: translateX(-50%) translateY(0); }
  .menu-mega .menu-item a { padding-top: 8px; padding-bottom: 8px; }
  .menu-mega-innr { display: flex; }
  .menu-mega-innr > div, .menu-mega-innr ul { flex-grow: 1; }
  .menu-mega-2clmn .menu-mega-innr > div, .menu-mega-2clmn .menu-mega-innr ul { width: 50%; }
  .menu-mega-3clmn .menu-mega-innr > div, .menu-mega-3clmn .menu-mega-innr ul { width: 33.33%; }
  .menu-mega-2clmn { min-width: 350px; max-width: 350px; }
  .menu-mega-3clmn { min-width: 460px; max-width: 460px; }
  .menu-btns { display: flex; margin-bottom: -10px; margin-left: 10px; }
  .has-sub > a { padding-right: 24px !important; position: relative; }
  .has-sub > a:after { right: 8px; }
  .has-sub.menu-item:last-child .menu-sub:not(.menu-mega) .menu-item > a { padding-right: 20px !important; padding-left: 35px; }
  .has-sub.menu-item:last-child .menu-sub:not(.menu-mega) .menu-item > a:after { transform: translateY(-50%) rotate(90deg); left: 15px; right: auto; }
  .has-sub.menu-item:last-child .menu-mega { left: auto; right: 0; transform: translateX(0) translateY(-8px); }
  .has-sub.menu-item:last-child:hover .menu-mega { transform: translateX(0) translateY(0); }
  .menu-sub .has-sub > a { padding-right: 35px; }
  .menu-sub .has-sub > a:after { transform: translateY(-50%) rotate(-90deg); right: 15px; } }
@media (min-width: 1200px) { .menu-item > a { padding: 20px 18px; }
  .menu-item.has-sub > a { padding-right: 34px !important; }
  .menu-item.has-sub > a:after { right: 18px; }
  .menu-sub a { padding: 10px 18px; } }
@media (min-width: 992px) { .is-transparent:not(.has-fixed) .menu > .menu-item > a { color: #fff; }
  .is-transparent:not(.has-fixed) .menu > .menu-item:hover > a { color: #16a3fe; } }
/** 05.03 Sections */
.nk-body { min-width: 320px; }
.nk-pages-centered { display: flex; flex-direction: column; justify-content: center; min-height: 100vh; padding: 0 15px; }

@media (min-width: 1600px) { .body-boxed .nk-wrap { max-width: 1400px; } }
.section { position: relative; overflow-x: hidden; width: 100%; z-index: 2; padding-top: 45px; padding-bottom: 45px; }

.section-s { padding-top: 60px; padding-bottom: 60px; }

.section-m { padding-top: 60px; padding-bottom: 60px; }

.section-l { padding-top: 60px; padding-bottom: 60px; }

.section-x { padding-top: 75px; padding-bottom: 75px; }

@media (min-width: 576px) { .section { padding-top: 51.4285714286px; padding-bottom: 51.4285714286px; }
  .section-s { padding-top: 17.1428571429px; padding-bottom: 17.1428571429px; }
  .section-m { padding-top: 34.2857142857px; padding-bottom: 34.2857142857px; }
  .section-l { padding-top: 68.5714285714px; padding-bottom: 68.5714285714px; }
  .section-x { padding-top: 85.7142857143px; padding-bottom: 85.7142857143px; } }
@media (min-width: 768px) { .section { padding-top: 60px; padding-bottom: 60px; }
  .section-s { padding-top: 20px; padding-bottom: 20px; }
  .section-m { padding-top: 40px; padding-bottom: 40px; }
  .section-l { padding-top: 80px; padding-bottom: 80px; }
  .section-x { padding-top: 100px; padding-bottom: 100px; } }
@media (min-width: 992px) { .section { padding-top: 90px; padding-bottom: 90px; }
  .section-s { padding-top: 30px; padding-bottom: 30px; }
  .section-m { padding-top: 60px; padding-bottom: 60px; }
  .section-l { padding-top: 120px; padding-bottom: 120px; }
  .section-x { padding-top: 150px; padding-bottom: 150px; } }
.split { height: 100%; }
.split-left { border-radius: 6px 6px 0 0; }
.split-right { border-radius: 0 0 6px 6px; }

@media (min-width: 576px) { .split-sm-left { border-radius: 6px 0 0 6px; }
  .split-sm-right { border-radius: 0 6px 6px 0; } }
@media (min-width: 768px) { .split-md-left { border-radius: 6px 0 0 6px; }
  .split-md-right { border-radius: 0 6px 6px 0; } }
@media (min-width: 992px) { .split-lg { border-radius: 6px; }
  .split-lg-left { border-radius: 6px 0 0 6px; }
  .split-lg-right { border-radius: 0 6px 6px 0; } }
@media (min-width: 1200px) { .split-xl-left { border-radius: 6px 0 0 6px; }
  .split-xl-right { border-radius: 0 6px 6px 0; } }
/** 05.04 Footer */
/** 06. Override */
.mfp-bg, .mfp-wrap, .mfp-wrap[class*="bg-"], .mfp-bg[class*="bg-"] { position: fixed !important; z-index: 99999999 !important; }

/** 07. Preview Purpose */
.nk-preview { position: relative; }
.nk-preview-content { padding-top: 10px; }
.nk-preview-content > .tab-pane { padding-top: 0; }
.nk-preview .nk-block-head .nav { margin-bottom: 25px; margin-left: 0; margin-right: 0; }
.nk-preview .nk-block-head .nav a { font-size: .8rem; padding: 10px 5px 5px; }
.nk-preview .nk-block-head .title { color: #415076; font-size: 13px; letter-spacing: 1px; text-transform: uppercase; }
.nk-preview .nk-block-head .title small { font-size: 12px; text-transform: none; letter-spacing: 0; margin-top: 0.45rem; color: #828998; display: block; }

@media (min-width: 1280px) { .nk-preview { padding-left: 48px; padding-right: 48px; }
  .nk-preview-content > .tab-pane { box-shadow: 0 5px 35px -5px rgba(18, 24, 58, 0.12); } }
/* END @iO */
