/**
Template :	ICOCrypto @by Softnio
Theme	 :  Azalea
Version	 :	1.6.0
Updated	 :	03.16.2019
*/
body,
html {
    font-family: "Roboto", sans-serif;
    color: #305082;
    font-size: 14px;
    line-height: 1.6;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}
@media (min-width: 576px) {
    body,
    html {
        font-size: 16px;
        line-height: 1.6;
        font-weight: 400;
    }
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #f3f3f3;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
}
.tc-light .h1,
.tc-light .h2,
.tc-light .h3,
.tc-light .h4,
.tc-light .h5,
.tc-light .h6,
.tc-light h1,
.tc-light h2,
.tc-light h3,
.tc-light h4,
.tc-light h5,
.tc-light h6 {
    color: #fff;
}

.lead {
    font-weight: 400;
}
.tc-light,
.tc-light a:not(.btn) {
    color: #97afd5 !important;
}
.tc-light a:not(.btn):hover {
    color: #fff !important;
}

.tc-light p {
    color: #97afd5;
}

.mfp-close-btn-in .mfp-close {
    background: #0f1932;
}

.btn{
    font-size: 12px;
    color: #fff;
    border: 2px solid #04b169;
    background-color: transparent;
    font-weight: bolder;
    padding-left: 18px;
    padding-right: 18px;
    letter-spacing: 0.05em;
  }
  .btn:not(.btn-outline):hover {
    border-color: #fff;
    background-color: #16a3fe;
    color: #fff;
  }

/* .btn {
    font-size: 12px;
    color: #fff;
    border: 2px solid #05b169;
    background-color: #05b169;
    padding-left: 18px;
    padding-right: 18px;
    letter-spacing: 0.05em;
}
.btn:not(.btn-outline):hover {
    border-color: #fff;
    background-color: #fff;
    color: #f42f54;
} */
.btn-md {
    font-size: 12px;
    line-height: 24px;
    padding: 10px 26px;
}
.btn-sm {
    font-size: 11px;
    line-height: 23px;
    padding: 8px 20px;
}
.btn-outline {
    color: #fff !important;
}
.btn-outline:not(:hover) {
    color: #305082;
    background-color: transparent !important;
}
.btn-underline {
    padding-left: 0 !important;
    padding-right: 0 !important;
    color: #f42f54;
    background: none !important;
    border-color: transparent !important;
    text-decoration: none;
}
.btn-underline:focus,
.btn-underline:hover {
    color: #fff;
    text-decoration: none;
}

.lead {
    font-size: 1rem;
}

.title {
    font-size: 1.4rem;
    font-weight: 700;
}
.title-xxs {
    font-size: 12px;
    text-transform: uppercase;
}
.title-xs {
    font-size: 0.88rem;
    letter-spacing: 0.2em;
}
.title-sm {
    font-size: 1.15rem;
}
.title-md {
    font-size: 1.375rem;
}
.title-md:not(:last-child) {
    margin-bottom: 1rem;
}
.title-lg {
    font-size: 1.5rem;
}
.title-xl {
    font-size: 2.2rem;
}
.banner-caption .title:not([class*='title-']) {
    font-size: 1.75rem;
}
@media (min-width: 576px) {
    .btn {
        min-width: 190px;
    }
    .btn-auto {
        min-width: 0;
    }
    .lead {
        font-size: 1.15rem;
    }
    .title {
        font-size: 1.8rem;
        font-weight: 700;
    }
    .title.title-xxs {
        font-size: 12px;
        text-transform: uppercase;
    }
    .title.title-xs {
        font-size: 0.88rem;
        letter-spacing: 0.2em;
    }
    .title.title-sm {
        font-size: 1.15rem;
    }
    .title.title-md {
        font-size: 1.375rem;
    }
    .title.title-lg {
        font-size: 1.5rem;
    }
    .title.title-xl {
        font-size: 2.2rem;
    }
    .banner-caption .title:not([class*='title-']) {
        font-size: 2rem;
    }
}
@media (min-width: 992px) {
    .lead {
        font-size: 1.25rem;
    }
    .title {
        font-size: 2.15rem;
    }
    .title.title-lg {
        font-size: 1.75rem;
    }
    .title.title-xl {
        font-size: 3rem;
    }
    .banner-caption .title:not([class*='title-']) {
        font-size: 2.5rem;
    }
}
@media (min-width: 1600px) {
    .banner-caption .title:not([class*='title-']) {
        font-size: 3rem;
    }
}
.section-head-s2 .title:before {
    background-image: linear-gradient(180deg, #305082 0%, #97afd5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.firefox .section-head-s2 .title:before {
    -webkit-text-fill-color: #fff;
}
.tc-light .section-head p {
    color: #97afd5;
}

.bg-theme {
    background-color: #0b0e11;
}
.bg-theme-alt {
    background-color: #1c2640;
}
.bg-theme-grad {
    background-image: linear-gradient(90deg, #0f1932 0%, #1c2640 100%);
}
.bg-theme-grad-alt {
    background-image: linear-gradient(90deg, #1c2640 0%, #0f1932 100%);
}
.bg-theme-dark {
    background-color: #0f1932;
}
.bg-theme-dark-alt {
    background-color: #1c2640;
}
.bg-theme-light {
    background-color: #ddebff;
}
.bg-theme-light-alt {
    background-color: #c4d9f9;
}
.bg-vr-line {
    background-image: url("../images/vr-line.png");
    background-repeat: repeat;
    background-size: auto;
}
.overlay-theme:after {
    background: #0f1932;
}
.overlay-theme-dark:after {
    background: #0f1932;
}

.header-main {
    padding: 12px 0;
}
.has-fixed:not(.is-dark) .header-main {
    background: transparent;
}
.has-fixed.is-dark .header-main {
    background-color: transparent;
    box-shadow: none;
}

.is-dark .header-navbar::before {
    background: #0b1326;
}

.is-dark .menu-sub {
    background: #0b1326;
}
.is-transparent:not(.has-fixed) .menu > .menu-item:hover > a,
.menu-sub .menu-item:hover > a {
    color: #f42f54;
}

.menu-item a {
    color: #fff;
}
.menu-item a.active,
.menu-item a:hover {
    color: #f42f54;
}

.cpn-action {
    margin-top: 30px;
}
@media (min-width: 768px) and (max-width: 991px) {
    .banner-gfx-auto img {
        max-width: 510px;
        margin: auto;
        display: block;
    }
}
@media (min-width: 992px) {
    .is-transparent .banner {
        padding-top: 130px;
    }
  }
    .cpn-action {
        margin-top: 45px;
    }
    .has-fixed.is-dark .header-main {}
    @media (min-width: 992px) and (min-height: 1024px) and (max-height: 1366px) {
        .is-transparent .banner-fs:not(.has-gfx) {
            min-height: 100vh;
            padding-top: 120px;
        }
    }
    @media (min-width: 360px) and (max-width: 460px) and (min-height: 640px) and (max-height: 820px) {
        .is-transparent .banner-fs:not(.has-gfx) {
            padding-top: 180px;
        }
        .nk-block-header + .nk-block-status {
            padding-top: 120px;
        }
        .has-gfx .nk-block-header + .nk-block-status {
            padding-top: 60px;
        }
    }
    @media (min-width: 992px) and (min-height: 600px) and (max-height: 1024px) {
        .banner-caption .title {
            font-size: 1.8rem;
        }
        .is-transparent .banner-fs:not(.has-gfx) {
            padding-top: 110px;
        }
        .nk-block-header + .nk-block-status {
            padding-top: 60px;
        }
        .nk-block-status + .nk-block-actions {
            padding-top: 20px;
        }
        .cpn-action {
            margin-top: 25px;
        }
    }
    .countdown-s3 .countdown-time {
        color: #fff;
        font-family: "Quicksand", sans-serif;
    }
    .countdown-s3-alt .countdown-text {
        color: #97afd5;
    }

    .progress-bar {
        background: #3e5b88;
    }
    .progress-percent {
        background: #f42f54;
    }
    .progress-percent-s2 {
        z-index: 2;
    }
    .progress-percent-s2:after {
        position: absolute;
        content: '';
        top: 50%;
        right: 0;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        border: 2px solid #fff;
        background: #f42f54;
        transform: translate(50%, -50%);
    }
    .progress-point {
        color: #bed8ff;
    }
    .progress-point:after {
        height: 30px;
    }
    .progress-info-s2 {
        padding: 0 0 17px;
    }
    .progress-info-s2 span {
        color: #fff;
    }

    .feature-icon .icon-grd {
        background-image: linear-gradient(-45deg, #f42f54 0%, #41538e 80%, #41538e 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .feature-icon .icon-circle {
        position: relative;
        height: 36px;
        width: 36px;
        line-height: 36px;
        text-align: center;
        color: #305082;
        border-radius: 50%;
        background-image: linear-gradient(-45deg, #f42f54 0%, #41538e 80%, #41538e 100%);
    }
    .feature-icon .icon-circle:after {
        position: absolute;
        top: 2px;
        left: 2px;
        bottom: 2px;
        right: 2px;
        content: '';
        border-radius: 50%;
        background: #0f1932;
        z-index: -1;
    }
    .tc-light .feature-icon .icon-circle {
        color: #41538e;
    }
    .feature-6:after {
        background: #f42f54;
    }
    @media (min-width: 992px) {
        .feature-6-1 {
            padding-top: 197px;
        }
        .feature-6-2 {
            padding-top: 73px;
        }
        .feature-6-3 {
            padding-top: 0;
        }
        .feature-6-4 {
            padding-top: 184px;
        }
    }
    .tab-nav-sap {
        background: #2d3f5f;
        margin: 0 -7px;
    }
    .tab-nav-sap:after {
        content: '';
        flex-grow: 1;
        height: 2px;
    }
    .tab-nav-btn {
        padding-bottom: 15px;
    }
    .tab-nav-btn li a {
        color: #fff !important;
        border: 1px solid #2d3f5f;
    }
    .tc-light .tab-nav-btn li a {
        color: #fff !important;
        border-color: #2d3f5f !important;
        background-color: transparent;
    }
    .tab-nav-btn li a.active,
    .tab-nav-btn li a:hover {
        color: #f42f54 !important;
        border-color: #f42f54 !important;
    }
    .tc-light .tab-nav-btn li a.active,
    .tc-light .tab-nav-btn li a:hover {
        color: #fff !important;
        background-color: #f42f54;
    }
    .tab-nav-btn-bdr li a {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        font-weight: 700;
        font-family: "Quicksand", sans-serif;
        color: #305082;
        border: 2px solid #2d3f5f;
        padding: 8px 12px;
    }
    .tab-nav-btn-bdr li a.active,
    .tab-nav-btn-bdr li a:hover {
        color: #f42f54 !important;
        border-color: #f42f54;
        background-color: transparent;
    }
    .tc-light .tab-nav-btn-bdr li a.active,
    .tc-light .tab-nav-btn-bdr li a:hover {
        color: #f42f54 !important;
        background-color: transparent;
    }
    @media (min-width: 576px) {
        .tab-nav li a {
            padding: 12px 20px;
        }
        .tab-nav-btn-bdr li a {
            font-size: 14px;
            letter-spacing: 0.2em;
            padding: 14px 20px;
        }
    }
    .roadmap-year-s2 {
        padding-bottom: 65px;
    }
    .tc-light .roadmap-finished .roadmap-year-s2 {
        color: #f42f54;
    }
    .roadmap-running .roadmap-year-s2 {
        opacity: 1;
    }
    .tc-light .roadmap-running .roadmap-year-s2 {
        color: #f42f54;
    }
    .roadmap-wrap-ovl-right:after {
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(15, 25, 50, 0.8) 25%, #0f1932 60%, #0f1932 100%);
    }
    .roadmap-wrap-ovl-left:before {
        background-image: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(15, 25, 50, 0.8) 25%, #0f1932 60%, #0f1932 100%);
    }
    .roadmap-s2:before {
        background: #f42f54;
    }
    .roadmap-s2:after {
        background-color: #0f1932;
    }
    .roadmap-s2.roadmap-finished:after,
    .roadmap-s2.roadmap-running:after {
        background-color: #f42f54;
        box-shadow: none;
        transition: box-shadow 0.3s;
    }
    .roadmap-s2.roadmap-finished:before {
        width: 100%;
    }
    .roadmap-s2.roadmap-running:before {
        width: 50%;
    }
    .roadmap-s2.roadmap-running:after {
        box-shadow: 0 0 0 12px rgba(255, 255, 255, 0.1);
    }
    .roadmap-s2.roadmap-i1:before {
        transition-delay: 1.4s;
    }
    .roadmap-s2.roadmap-i1:after {
        transition-delay: 1.7s;
    }
    .roadmap-s2.roadmap-i2:before {
        transition-delay: 1.7s;
    }
    .roadmap-s2.roadmap-i2:after {
        transition-delay: 2.0s;
    }
    .roadmap-s2.roadmap-i3:before {
        transition-delay: 2.0s;
    }
    .roadmap-s2.roadmap-i3:after {
        transition-delay: 2.3s;
    }
    .roadmap-s2.roadmap-i4:before {
        transition-delay: 2.3s;
    }
    .roadmap-s2.roadmap-i4:after {
        transition-delay: 2.7s;
    }
    .roadmap-s2.roadmap-i5:before {
        transition-delay: 2.6s;
    }
    .roadmap-s2.roadmap-i5:before {
        transition-delay: 2.9s;
    }
    .roadmap-s2.roadmap-i6:before {
        transition-delay: 2.9s;
    }
    .roadmap-s2.roadmap-i6:before {
        transition-delay: 3.2s;
    }
    .animate-start .roadmap-s2.roadmap-finished:before {
        width: 100%;
    }
    .animate-start .roadmap-s2.roadmap-running:before {
        width: 50%;
    }
    .animate-start .roadmap-s2.roadmap-running:after {
        box-shadow: 0 0 0 12px rgba(255, 255, 255, 0.1);
    }

    .team-show {
        background-image: linear-gradient(-45deg, #f42f54 0%, #0f1932 100%);
    }
    .team-photo-s2:before {
        background-image: linear-gradient(-45deg, #f42f54 0%, #0f1932 100%);
        opacity: 0.4;
    }
    @media (min-width: 992px) {
        .team-s2.team-even {
            margin-top: 60px;
        }
    }
    .field-label {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.2em;
    }
    .tc-light .field-label {
        color: #fff;
    }

    .input-bordered {
        border-radius: 2px;
        color: #97afd5;
        border-color: rgba(101, 119, 151, 0.4);
    }
    .input-bordered::-webkit-input-placeholder {
        color: #97afd5;
        opacity: 0.4;
    }
    .input-bordered::-moz-placeholder {
        color: #97afd5;
        opacity: 0.4;
    }
    .input-bordered:-ms-input-placeholder {
        color: #97afd5;
        opacity: 0.4;
    }
    .input-bordered:-moz-placeholder {
        color: #97afd5;
        opacity: 0.4;
    }
    .input-bordered:focus {
        border-color: rgba(101, 119, 151, 0.8);
    }

    .contact-icon {
        color: #bed8ff;
        background: rgba(190, 216, 255, 0.15);
    }

    .social-links-s2 li a {
        border: none;
    }
    .social-links-s2 li a:hover {
        background-color: transparent;
        color: #f42f54 !important;
    }
    .tc-light .social-links-s2 li a:hover {
        color: #f42f54 !important;
    }

    .copyright-text,
    .copyright-text a,
    .footer-nav li a {
        color: #97afd5;
    }
    .copyright-text a:hover,
    .footer-nav li a:hover {
        color: #f42f54 !important;
    }

    .accordion-item-s3 {
        background: rgba(28, 38, 64, 0.4);
        box-shadow: none;
    }
    .accordion-item-s3:not(:last-child) {
        margin-bottom: 15px;
    }
    .accordion-title {
        color: #0f1932;
    }
    .accordion-title-sm {
        font-weight: 600;
    }
    .accordion-item-s3 .accordion-title {
        padding: 19px 41px 11px 25px;
    }
    .tc-light .accordion-title {
        color: #f42f54;
    }
    .accordion-title.collapsed {
        color: #305082;
    }
    .tc-light .accordion-title.collapsed {
        color: #fff;
    }
    .accordion-icon:after,
    .accordion-icon:before {
        color: #1c2640;
    }
    .accordion-item-s3 .accordion-icon {
        top: 13px;
        right: 13px;
    }
    .tc-light .accordion-icon {
        background: transparent;
    }
    .tc-light .accordion-icon:after,
    .tc-light .accordion-icon:before {
        color: #97afd5;
    }
    .accordion-content {
        padding-bottom: 12px;
        padding-right: 46px;
    }
    .accordion-item-s3 .accordion-content {
        padding-left: 25px;
    }

    .doc-title small {
        color: #97afd5;
    }
    .doc-download {
        color: #fff;
        background: #f42f54;
    }

    .tc-light a.doc-download {
        color: #fff !important;
    }
    .tc-light a.doc-download:hover {
        color: #f42f54 !important;
        background: #fff;
    }
    .footer-nav li a:hover {
        color: #f42f54;
    }

    .ovm-line {
        position: relative;
        width: 64%;
        max-width: 1280px;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        border-left: 1px solid rgba(255, 255, 255, 0.05);
        border-right: 1px solid rgba(255, 255, 255, 0.05);
    }
    .ovm-line:after,
    .ovm-line:before {
        position: absolute;
        content: '';
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    .ovm-line:before {
        width: 1px;
        background: rgba(255, 255, 255, 0.05);
    }
    @media (min-width: 992px) {
        .ovm-line:after {
            width: 50%;
            border-left: 1px solid rgba(255, 255, 255, 0.05);
            border-right: 1px solid rgba(255, 255, 255, 0.05);
        }
    }
    @keyframes rotatecw {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes rotateacw {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(-360deg);
        }
    }
    .nk-circle-animation {
        height: 300px;
        width: 300px;
        z-index: 0;
    }
    .nk-circle-animation + * {
        position: relative;
        z-index: 1;
    }
    .nk-circle-animation:after,
    .nk-circle-animation:before {
        position: absolute;
        content: '';
        border: 4px solid rgba(101, 119, 151, 0.3);
        border-radius: 50%;
    }
    .nk-circle-animation:before {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-top-color: #f42f54;
        border-right-color: #f42f54;
        animation: rotatecw 30s linear infinite 0s;
    }
    .nk-circle-animation:after {
        top: 25px;
        left: 25px;
        bottom: 25px;
        right: 25px;
        border-top-color: #f42f54;
        border-bottom-color: #f42f54;
        animation: rotateacw 24s linear infinite 0s;
    }
    .nk-circle-animation.small:after,
    .nk-circle-animation.small:before {
        border-width: 2px;
    }
    .nk-circle-animation.fast:before {
        animation: rotatecw 15s linear infinite 0s;
    }
    .nk-circle-animation.fast:after {
        animation: rotateacw 10s linear infinite 0s;
    }
    .nk-circle-animation.white:before {
        border-top-color: #fff;
        border-right-color: #fff;
    }
    .nk-circle-animation.white:after {
        border-top-color: #fff;
        border-bottom-color: #fff;
    }
    @media (min-width: 576px) {
        .nk-circle-animation {
            height: 500px;
            width: 500px;
            overflow: hidden;
        }
        .nk-circle-animation:after {
            top: 45px;
            left: 45px;
            bottom: 45px;
            right: 45px;
        }
        .nk-circle-animation.small {
            height: 400px;
            width: 400px;
        }
        .nk-circle-animation.small:after {
            top: 35px;
            left: 35px;
            bottom: 35px;
            right: 35px;
        }
    }
    .word-split {
        display: flex;
        flex-flow: row wrap;
        margin-left: -0.125em;
        margin-right: -0.125em;
    }
    .text-center .word-split,
    .word-split.text-center {
        justify-content: center;
    }
    .word-x {
        overflow: hidden;
        position: relative;
    }
    .word-x > span {
        display: inline-block;
        margin-left: 0.125em;
        margin-right: 0.125em;
    }

    .element-x {
        overflow: hidden;
        position: relative;
    }
    .element-x > span {
        display: inline-block;
    }
    .section-reveal:after,
    .section-reveal:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        content: '';
        transition: all 0.6s;
    }
    .section-reveal.section-active:after,
    .section-reveal.section-active:before {
        height: 0;
    }
    .section-reveal.section-active:before {
        transition-delay: 0.4s;
    }
    .section-reveal.section-active:after {
        transition-delay: 0.8s;
    }
    .section-reveal:before {
        background-color: #0f1932;
        z-index: 9;
    }
    .section-reveal:after {
        background-color: #1b253c;
        z-index: 8;
    }

    .slide-up-mask .element-x > span,
    .slide-up-mask .word-x > span {
        transform: translateY(100%);
    }
    .slide-down-mask .element-x > span,
    .slide-down-mask .word-x > span {
        transform: translateY(-100%);
    }
    .slide-down-mask .element-x > span,
    .slide-down-mask .word-x > span,
    .slide-up-mask .element-x > span,
    .slide-up-mask .word-x > span {
        visibility: hidden;
    }
    .animate-start .slide-down-mask .element-x > span,
    .animate-start .slide-down-mask .word-x > span,
    .animate-start .slide-up-mask .element-x > span,
    .animate-start .slide-up-mask .word-x > span {
        transition: transform 0.6s cubic-bezier(0.8, 0.14, 0.24, 0.99) 0s;
        transform: translateY(0) translateZ(0);
        visibility: visible;
        transition-delay: 1s;
    }
    .slide-down-mask:before,
    .slide-up-mask:before {
        opacity: 0;
        transform: translateX(-50%) translateY(-40px);
    }
    .animate-start .slide-down-mask:before,
    .animate-start .slide-up-mask:before {
        transition: all 0.6s;
        opacity: 0.06;
        transform: translateX(-50%) translateY(0);
    }
    .firefox .animate-start .slide-down-mask:before,
    .firefox .animate-start .slide-up-mask:before {
        opacity: 0.03;
    }
    .slide-left-mask .element-x > span,
    .slide-left-mask .word-x > span {
        transform: translateX(-100%);
    }
    .slide-right-mask .element-x > span,
    .slide-right-mask .word-x > span {
        transform: translateX(100%);
    }
    .slide-left-mask .element-x > span,
    .slide-left-mask .word-x > span,
    .slide-right-mask .element-x > span,
    .slide-right-mask .word-x > span {
        visibility: hidden;
    }
    .animate-start .slide-left-mask .element-x > span,
    .animate-start .slide-left-mask .word-x > span,
    .animate-start .slide-right-mask .element-x > span,
    .animate-start .slide-right-mask .word-x > span {
        transition: transform 0.6s cubic-bezier(0.8, 0.14, 0.24, 0.99) 0s;
        transform: translateX(0) translateZ(0);
        visibility: visible;
    }

    .fade-in-up {
        visibility: hidden;
        opacity: 0;
        transform: translateY(40px);
    }
    .animate-start .fade-in-up {
        transition: all 0.6s cubic-bezier(0.8, 0.14, 0.24, 0.99) 0s;
        transform: translateY(0) translateZ(0);
        visibility: visible;
        opacity: 1;
        transition-delay: 1s;
    }
    .fade-in-down {
        visibility: hidden;
        opacity: 0;
        transform: translateY(-40px);
    }
    .animate-start .fade-in-down {
        transition: all 0.6s cubic-bezier(0.8, 0.14, 0.24, 0.99) 0s;
        transform: translateY(0) translateZ(0);
        visibility: visible;
        opacity: 1;
        transition-delay: 1s;
    }
    .fade-in-left {
        visibility: hidden;
        opacity: 0;
        transform: translateX(-40px);
    }
    .animate-start .fade-in-left {
        transition: all 0.6s cubic-bezier(0.8, 0.14, 0.24, 0.99) 0s;
        transform: translateX(0) translateZ(0);
        visibility: visible;
        opacity: 1;
        transition-delay: 1s;
    }
    .fade-in-right {
        visibility: hidden;
        opacity: 0;
        transform: translateX(40px);
    }
    .animate-start .fade-in-right {
        transition: all 0.6s cubic-bezier(0.8, 0.14, 0.24, 0.99) 0s;
        transform: translateX(0) translateZ(0);
        visibility: visible;
        opacity: 1;
        transition-delay: 1s;
    }

    .zoom-in {
        visibility: hidden;
        opacity: 0;
        transform: scale(0.5);
    }
    .animate-start .zoom-in {
        transition: all 0.6s cubic-bezier(0.8, 0.14, 0.24, 0.99) 0s;
        transform: scale(1);
        visibility: visible;
        opacity: 1;
        transition-delay: 1s;
    }
    .zoom-out {
        visibility: hidden;
        opacity: 1;
        transform: scale(1);
    }
    .animate-start .zoom-out {
        transition: all 0.6s cubic-bezier(0.8, 0.14, 0.24, 0.99) 0s;
        transform: scale(1.5);
        visibility: visible;
        opacity: 0;
        transition-delay: 1s;
    }

    .preloader.no-default {
        background-color: #0f1932;
    }
    .preloader.no-default:after,
    .preloader.no-default:before {
        background-color: #0f1932;
    }